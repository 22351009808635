import React from "react";
import "./Hero.scss";
import BackVideo from "../../../assets/video/rrropt.mp4";

import EXP from "../../../assets/logos/exp.png";
import GAF from "../../../assets/logos/gaf.png";
import RCA from "../../../assets/logos/rca.png";
import HAR from "../../../assets/logos/har.png";
import HOM from "../../../assets/logos/hom.png";
import LO from "../../../assets/logos/logo6a.jpg";

export default function Hero() {
  return (
    <div className="heroBase">
      <div className="topHeroDivVideo">
        <div className="greyDiv"></div>
        <video className="videoTag" autoPlay="autoplay" loop muted playsInline>
          <source src={BackVideo} type="video/mp4" />
        </video>
        <div className="topHeroDivData">
          <div className="dataContentHero">
            <p className="heroTitleTxt">Your</p>
            <p className="heroTitleTxt">local roofing</p>
            <p className="heroTitleTxt">professionals</p>
            <h1 className="heroTitleSubTxt">
              Roofing & Remodeling services throughout the greater Houston area
            </h1>
            <div className="heroDataBottomDiv">
              <div className="heroDataLeft">
                <p className="heroSubTxt">5.0 Rating</p>
                <p className="heroMiniSubTxt">Google Review</p>
              </div>
              <div className="heroDataRight">
                <img
                  src="https://www.pngplay.com/wp-content/uploads/6/Golden-5-Star-Rating-PNG.png"
                  alt="rating"
                  className="ratingStarImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomLogoHeroDiv">
        <div className="bottomDataDiv">
          <div className="respOnlyDiv">
            <div className="logosFlexBase">
              <div className="logoGrid">
                <img
                  src={GAF}
                  alt="restoration best company certification"
                  className="heroLogo"
                />
              </div>
              <div className="logoGrid">
                <img
                  src={HOM}
                  alt="Approved company for Roofing"
                  className="heroLogo"
                />
              </div>
              <div className="logoGrid">
                <img
                  src={RCA}
                  alt="Certified company lists for Roofing"
                  className="heroLogo"
                />
              </div>
              <div className="logoGrid">
                <img
                  src={HAR}
                  alt="HARCA Certification for roofing and restoration"
                  className="heroLogo"
                />
              </div>
              <div className="logoGrid">
                <img
                  src={EXP}
                  alt="Remodeling certificate"
                  className="heroLogo"
                />
              </div>
              <div className="logoGrid">
                <img
                  src={LO}
                  alt="Remodeling Diamond certified"
                  className="heroLogo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
