import React, { useState } from "react";
import "./Service.scss";
import TopCard from "../../components/serviceSessions/topCard/TopCard";
import ServiceList from "../../components/serviceSessions/serviceList/ServiceList";
import TopView from "../../components/serviceSessions/topView/TopView";
import Slider from "../../components/serviceSessions/slider/Slider";
import TopButton from "../../components/serviceSessions/topButton/TopButton";

export default function Service({ data }) {
  console.log("data", data);
  const [showSpring, setShowSpring] = useState(false);
  return (
    <div>
      <TopButton setShowSpring={setShowSpring} showSpring={showSpring} />

      {showSpring ? (
        <>
          <TopView
            heroImg={data.spring.heroImg}
            title={data.spring.title}
            desp={data.spring.desp}
          />
          <TopCard
            cardImg={data.spring.cardImg}
            title={data.spring.title}
            txt1={data.spring.cardTxt1}
            txt2={data.spring.cardTxt2}
          />
          <ServiceList data={data.spring.services} />
          <Slider data={data.spring.portfolio} />
        </>
      ) : (
        <>
          <TopView
            heroImg={data.houston.heroImg}
            title={data.houston.title}
            desp={data.houston.desp}
          />
          <TopCard
            cardImg={data.houston.cardImg}
            title={data.houston.title}
            txt1={data.houston.cardTxt1}
            txt2={data.houston.cardTxt2}
          />
          <ServiceList data={data.houston.services} />
          <Slider data={data.houston.portfolio} />
        </>
      )}
    </div>
  );
}
