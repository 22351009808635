import React from "react";
import Hero from "../../components/homeSessions/hero/Hero";
import CardTop from "../../components/homeSessions/cardTop/CardTop";
import BlockSession from "../../components/homeSessions/blockSession/BlockSession";
import MiddleCard from "../../components/homeSessions/middleCard/MiddleCard";
import ScheduleSession from "../../components/homeSessions/scheduleSession/ScheduleSession";
import CardBottom from "../../components/homeSessions/cardBottom/CardBottom";
import VideoSession from "../../components/homeSessions/videoSession/VideoSession";
import { Helmet } from "react-helmet";

export default function HomeScreen() {
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
        <meta
          name="keywords"
          content="roofing remodeling restoration, roofing repair, roofing services, home renovation, home improvement, roof installation, roof maintenance, roof replacement, residential roofing, commercial roofing, roof inspection, roofing contractors, roofing company, roof leaks, roof cleaning, roof waterproofing, remodeling services, interior remodeling, exterior remodeling, kitchen remodeling, bathroom remodeling, home restoration, historical restoration, building restoration, storm damage repair, roof insulation, gutter installation, gutter repair"
        />
        <link
          rel="apple-touch-icon"
          href="/logo192.png"
          sizes="192x192"
        />
        <meta
          property="og:image"
          href="/logo512.png"
          sizes="512x512"
        />
        <meta name="twitter:image" href="/logo512.png" />
        <link rel="icon" href="/favicon.ico" sizes="48x48" />
      </Helmet>
      <Hero />
      <CardTop />
      <BlockSession />
      <MiddleCard />
      <ScheduleSession />
      <VideoSession />
      <CardBottom />
    </div>
  );
}
