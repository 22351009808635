import React from "react";
import "./RoofingTop.scss";
import IMG from "../../../assets/data/house1.jpg";
import GAF from "../../../assets/data/gafM.png";
import { Link } from "react-router-dom";
export default function RoofingTop() {
  return (
    <div className="roofTopBase">
      <div className="shadowroofTop"></div>
      <img className="toproofImage" src={IMG} alt="toproof" />
      <div className="toproofTxtDivision">
        <div className="toproofTxtTitle">GAF Premium Roofing Shingles</div>

        <div className="toproofTxtDesp">
          Nothing Protects Quite Like A Roof. And no warranties can cover your
          home quite like GAF's Lifetime Roofing System
        </div>
        <img src={GAF} alt="gaf" className="gafIMg" />
        <Link to="/warrenty" style={{ textDecoration: "none" }}>
          <button style={{ marginTop: "20px" }} className="blueBtn">
            COMPARE WARRANTIES
          </button>
        </Link>
      </div>
    </div>
  );
}
