import React from "react";
import IMG1 from "../../../assets/svc/complete/1.jpeg";
import IMG2 from "../../../assets/svc/complete/2.jpeg";
import IMG3 from "../../../assets/svc/complete/3.png";
import IMG4 from "../../../assets/svc/complete/4.png";
import IMG5 from "../../../assets/svc/complete/5.png";
import IMG6 from "../../../assets/svc/complete/6.png";
import IMG7 from "../../../assets/svc/complete/7.png";
import IMG8 from "../../../assets/svc/complete/8.png";
import IMG9 from "../../../assets/svc/complete/9.png";
import IMG10 from "../../../assets/svc/complete/10.png";
import IMG11 from "../../../assets/svc/complete/11.png";
import Service from "../Service";
import { Helmet } from "react-helmet";
export default function RoofRepair() {
  const data = {
    houston: {
      heroImg:
        "https://cdn.pixabay.com/photo/2023/03/21/15/06/roof-7867482_1280.jpg",
      title: "Roof Repair in Houston, TX",
      desp: "Get Expert Roof Repair in Houston, TX",
      cardTxt1:
        "Whether you've suffered a leak, rainstorm, or accidental roof damage, it's important to get professional roof repair services as soon as possible. A botched repair can lead to serious problems down the road, including water damage, structural failure, and even roof leaks. If you are looking for professional roof repair in Houston, TX, then Restoration Roofing & Remodeling is the company for you. Our team of experienced professionals can help you restore your roof to its original condition and look like new again. We have a wide range of services that we can offer you, including roofing replacement, patching and repairs, and even coating services to protect your roof from weather damage.",
      cardTxt2: "",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
    spring: {
      heroImg:
        "https://cdn.pixabay.com/photo/2023/03/21/15/06/roof-7867482_1280.jpg",
      title: "Roof Repair in Spring, TX",
      desp: "Get Expert Roof Repair in Spring, TXX",
      cardTxt1:
        "Roof repair restoration services can be extremely helpful when restoring your home. Many times, roof repairs can require extensive work that may not be possible or feasible to complete by a homeowner. Restoration Roofing & Remodeling is here to help with all of your restoration needs, from small repairs and replacements to full roof restoration. If you're in need of a professional roof repair in Spring, TX, Restoration Roofing & Remodeling can help. Our team has over 25 years of experience in the industry, and we're dedicated to providing top-quality services. We'll work with you to find a solution that meets your needs and fits your budget.",
      cardTxt2:
        "Whatever your needs, we are confident that we can provide you with the quality restoration roofing and remodeling services you need to get your life back on track. Give us a call today!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>Roof Repair in Texas | Roof repair contractors </title>
        <meta
          name="description"
          content="Restorationroofingremodelling is top Roof repair company in Texas. Get  professional roof repair services near Spring, Texas. Roofing contractors near Texas."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
