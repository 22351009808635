import React from "react";
// import "./RoofingTop.scss";
import IMG from "../../../src/assets/data/commercial.jpg";
// import GAF from "../../../assets/data/gafM.png";
import { Link } from "react-router-dom";
export default function CommercialTop() {
  return (
    <div className="roofTopBase">
      <div className="shadowroofTop"></div>
      <img className="toproofImage" src={IMG} alt="toproof" />
      <div className="toproofTxtDivision">
        <div className="toproofTxtTitle">Commercial Roofing</div>

        <div className="toproofTxtDesp">
        Restoration Roofing & Remodeling Is the top Houston, TX commercial roofing company specializing in all sorts of commercial roofing services        </div>
        {/* <img src={GAF} alt="gaf" className="gafIMg" /> */}
        {/* <Link to="/warrenty" style={{ textDecoration: "none" }}>
          <button style={{ marginTop: "20px" }} className="blueBtn">
            COMPARE WARRANTIES
          </button>
        </Link> */}
      </div>
    </div>
  );
}
