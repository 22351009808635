import React from "react";
import "./TopView.scss";

export default function TopView(props) {
  return (
    <div className="svcTopBase">
      <div className="shadowSvcTop"></div>
      <img className="topSvcImage" src={props.heroImg} alt="topSvc" />
      <div className="topSvcTxtDivision">
        <div className="topSvcTxtTitle">{props.title}</div>
        <div className="topSvcTxtDesp">{props.desp}</div>
      </div>
    </div>
  );
}
