import React from "react";
import "./TopSession.scss";
import IMG from "../../../assets/data/roof-top.jpg";
export default function TopSession() {
  return (
    <div className="roofTopBase">
      <div className="shadowroofTop"></div>
      <img className="toproofImage" src={IMG} alt="toproof" />
      <div className="toproofTxtDivision">
        <div className="toproofTxtTitle">Repair or Replace?</div>

        <div className="toproofTxtDesp">
          If your roof isn't very old and you spot a few loose or damaged
          shingles, you may be able to just replace them. But for broader or
          more extensive damage, it may be time for a new roof. The key is a
          regular roof inspection, so you know what's going on up there. At
          Restoration Roofing & Remodeling, we can do a complete inspection of
          your roof, inside and out, and give you an accurate estimate of what
          damage there is and what it will take to repair or replace it.
        </div>
      </div>
    </div>
  );
}
