import React from "react";
import "./ContactSession.scss";
import { Link } from "react-router-dom";

export default function ContactSession() {
  return (
    <div className="contactHomeBase">
      <div className="contactHomeContent">
        <div className="contactHomeData">
          <p className="contactHomeTitleTxt">Get a Free Estimate today</p>
          <p className="contactHomeSubTxt">
            Discover your project's cost with our free estimate form. No
            commitments, just accurate quotes tailored to your needs
          </p>
          {/* <p className="contactHomeSubTxt">
            Top Roofing Company in Texas.We are your trusted partner in
            elevating the beauty and functionality of your home
          </p> */}
        </div>
        <div className="contactHomeData">
          <Link
            to="contact-us"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="whiteBtn">CONTACT NOW</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
