import React from "react";
import "./InsuranceCard.scss";
export default function InsuranceCard(props) {
  return (
    <div className="roofListBase2">
      <div className="roofListContent">
        <button
          className="Questions "
          onClick={() => {
            props.setId(0);
            if (0 === props.id) {
              props.setShow(!props.show);
            } else {
              props.setShow(true);
            }
          }}
        >
          <p className="qtn">
            BELOW ARE LINKS TO SEVERAL MAJOR INSURANCE COMPANIES AND THEIR CLAIM
            FORMS.
          </p>
          <img
            style={{
              transform:
                props.show && props.id === 0
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              transition: "transform 1.0s",
            }}
            src="https://static.vecteezy.com/system/resources/previews/006/827/566/non_2x/down-arrow-icon-sign-symbol-logo-vector.jpg"
            alt="btn"
            className="arrow"
          />
        </button>

        {props.show && props.id === 0 && (
          <div className="contentDataroofList">
            {props.data.map((data) => (
              <a href={data.link} className="cardDataroofLIst" key={data.id}>
                <div className="cardContentroofList">
                  <div className="shadowroofList"></div>
                  <img
                    src={data.image}
                    alt="roof"
                    className="roofListIMages1"
                  />
                  <p className="txtInroofCard">{data.title}</p>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
