import React, { useState } from "react";
import "./Slider.scss";
import Carousel from "react-multi-carousel";
import Popup from "../../popup/Popup";

export default function Slider(props) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [img, setImg] = useState(null);
  const [show, setShow] = useState(false);
  const togglePopup = () => {
    setShow(!show);
  };
  const showPopUp = (img) => {
    setImg(img);
    togglePopup();
  };

  return (
    <div className="svcSliderBase1">
      <div className="sliderSvcContent1">
        <div className="topSvcLis1t">
          <p className="svcListTitle1">Our Works</p>
        </div>
        <Carousel responsive={responsive}>
          {props.data.map((data) => (
            <div
              className="imageListDiv1"
              key={data.id}
              onClick={() => {
                showPopUp(data.img);
              }}
            >
              <img src={data.img} alt="img" className="worksImage" />
            </div>
          ))}
        </Carousel>
      </div>
      {show && <Popup img={img} closePopup={togglePopup} />}
    </div>
  );
}
