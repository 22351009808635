import React from "react";
import BackVideo from "../../../assets/video/timb.mp4";
export default function CardBottom() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <video
              className="cardImg"
              autoPlay="autoplay"
              loop
              muted
              playsInline
            >
              <source src={BackVideo} type="video/mp4" />
            </video>
            {/* <img src={IMG} alt="cardImg" className="cardImg" /> */}
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">
              Restoration Roofing & Remodeling Master Elite Roofers
            </p>
            <p className="cardSubTxt">
              More homeowners in North America rely on Timberline Shingles than
              any other brand. They offer just the right combination of beauty,
              performance, and reliability. Now with GAF Time-Release
              Algae-Fighting Technology and Layer-Lock Technology, Timberline
              HDZ offers everything you can expect from an architectural shingle
              roof and more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
