import React from "react";
import "./MiddleCard.scss";
import IMG from "../../../assets/svc/complete/4.png";
// import FIN from "../../../assets/finance.png";
import { Link } from "react-router-dom";

export default function MiddleCard() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid">
          <div className="textBaseMiddle">
            <p className="cardTitleTxtM">
              Break your project into predictable monthly payments
            </p>
            <p className="cardSubTxt">
              Restoration Roofing & Remodeling is partnered with Hearth to
              provide our customers with financing options.
            </p>
            <p className="cardSubTxt">
              With Hearth, you can see potential financing options for your
              upcoming project. A loan through Hearth helps you pay for your
              project through predictable, monthly payments without tapping into
              your home equity.
            </p>
            <div className="finBtn">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                to="/financing"
              >
                <button className="redBtn">VIEW MORE ABOUT FINANCING</button>
                {/* <img src={FIN} alt="cardImg" className="fiainceImg" /> */}
              </Link>
            </div>
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
      </div>
    </div>
  );
}
