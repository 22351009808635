import React from "react";
import "./TextView.scss";
export default function TextView(props) {
  return (
    <div className="txtViewBase1">
      <div className="txtViewContent1">
        <p className="detailsTitle">{props.data.title}</p>
        <div className="insranceDetailsDiv">
          <ul>
            {props.data.data.map((data) => (
              <li key={data.id}>
                <p className="ans22">{data.hard}:</p>
                <p className="ans1">{data.soft}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
