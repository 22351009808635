import React from "react";
import IMG1 from "../../../assets/svc/complete/1.jpeg";
import IMG2 from "../../../assets/svc/complete/2.jpeg";
import IMG3 from "../../../assets/svc/complete/3.png";
import IMG4 from "../../../assets/svc/complete/4.png";
import IMG5 from "../../../assets/svc/complete/5.png";
import IMG6 from "../../../assets/svc/complete/6.png";
import IMG7 from "../../../assets/svc/complete/7.png";
import IMG8 from "../../../assets/svc/complete/8.png";
import IMG9 from "../../../assets/svc/complete/9.png";
import IMG10 from "../../../assets/svc/complete/10.png";
import IMG11 from "../../../assets/svc/complete/11.png";
import Service from "../Service";
import { Helmet } from "react-helmet";
const Top = () => {
  return (
    <>
      <p>
        If you're in need of a professional roof inspection in Spring, TX,
        Restoration Roofing & Remodeling is here to help. A roof is one of the
        most important components of a home and it should be taken care of
        properly. A roof that is in good condition will not only protect your
        home from weather damage, but it can also add value to your property.
        There are many benefits to using restoration roofing & remodeling
        services in Spring, TX. Here are just a few:
      </p>
      <ul>
        <li style={{ textAlign: "left" }}>
          A professionally maintained roof can help protect you and your family
          from weather damage.
        </li>
        <li style={{ textAlign: "left" }}>
          A well-maintained roof can also add value to your home.
        </li>
        <li style={{ textAlign: "left" }}>
          Restoration Roofing & Remodeling professionals have the experience and
          expertise necessary to ensure that your roof is in good condition.
        </li>
        <li style={{ textAlign: "left" }}>
          Restoration Roofing & Remodeling can also replace or repair damaged
          roofs, making them more durable and resistant to future damage.
        </li>
      </ul>
    </>
  );
};
export default function RoofInspection() {
  const data = {
    houston: {
      heroImg:
        "https://www.eagleview.com/wp-content/uploads/2022/09/INS-drone-roof-inspection-imagesINS-Drone-inspection-for-roof-blog-header.jpg?w=1500",
      title: "Roof Inspection in Houston, TX",
      desp: "Expert Roof Inspection Service in Houston, TX. ",
      cardTxt1:
        "At Restoration Roofing & Remodeling, we offer a comprehensive roof inspection service that covers everything from the condition of your roof to the quality of your gutters. We understand the importance of keeping your home in top condition, and our roof inspectors are experts in their field. With years of experience in the roofing industry, they will be able to quickly identify any problems with your roof and provide you with a detailed report.",
      cardTxt2:
        "If you're looking for a reliable roof inspection service in Houston, TX, look no further than Restoration Roofing & Remodeling. Our team of experienced and certified professionals will thoroughly inspect your roof and provide you with an accurate assessment of its condition. We'll also provide you with a detailed report of our findings, as well as recommendations for any necessary repairs or replacements. What's more, we offer competitive rates on all of our services, so you can be sure you're getting the best value for your money. Contact us today to schedule a free consultation and learn more about what we can do for you.",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
    spring: {
      heroImg:
        "https://www.eagleview.com/wp-content/uploads/2022/09/INS-drone-roof-inspection-imagesINS-Drone-inspection-for-roof-blog-header.jpg?w=1500",
      title: "Roof Inspection in Spring, TX",
      desp: "Reliable Roof Inspection in Spring, TX",
      cardTxt1: <Top />,
      cardTxt2:
        "If you are looking for a reliable, professional roofing and remodeling service in Spring, TX, look no further than Restoration Roofing & Remodeling. Our team is available 24/7 to take care of all your needs. Contact us today to schedule a consultation!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
