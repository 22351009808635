import React from "react";
import "./TopButtons.scss";
export default function TopButtons({ changePage, setChangePage }) {
  return (
    <div className="topBtnBase1">
      <div className="contentBtnBase1">
        <button
          style={{
            backgroundColor: changePage === 0 ? "#fff" : "#312f8d",
            color: changePage === 0 ? "#312f8d" : "#fff",
          }}
          className="contentBase1"
          onClick={() => {
            setChangePage(0);
          }}
        >
          STORM DAMAGE
        </button>
        <button
          style={{
            backgroundColor: changePage === 1 ? "#fff" : "#312f8d",
            color: changePage === 1 ? "#312f8d" : "#fff",
          }}
          onClick={() => {
            setChangePage(1);
          }}
          className="contentBase1"
        >
          INSURANCE CLAIMS
        </button>
        <button
          style={{
            backgroundColor: changePage === 2 ? "#fff" : "#312f8d",
            color: changePage === 2 ? "#312f8d" : "#fff",
          }}
          onClick={() => {
            setChangePage(2);
          }}
          className="contentBase1"
        >
          PREVENTATIVE MAINTENANCE
        </button>
      </div>
    </div>
  );
}
