export const array = [
  {
    id: 0,
    title: "AAA",
    link: "https://chicago.aaa.com/insurance/accident_assist.aspx?",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/American-Automobile-Association-Logo.svg/1200px-American-Automobile-Association-Logo.svg.png",
  },
  {
    id: 1,
    title: "AIG",
    link: "https://www.aig.com/individual/individual-claims",
    image: "https://1000logos.net/wp-content/uploads/2021/04/AIG-logo.png",
  },
  {
    id: 2,
    title: "ALLSTATE",
    link: "https://www.allstate.com/claims.aspx",
    image: "https://www.pngmart.com/files/13/Allstate-Logo-PNG-Pic.png",
  },
  {
    id: 3,
    title: "AMERICAN FAMILY",
    link: "https://www.amfam.com/claims/default.asp",
    image:
      "https://1000logos.net/wp-content/uploads/2022/12/American-Family-Insurance-logo.png",
  },
  {
    id: 4,
    title: "AMICA",
    link: "https://www.amica.com/en/claim-center/report-a-claim.html",
    image:
      "https://www.seekpng.com/png/full/330-3309377_amica-mutual-insurance-company-amica-mutual-insurance-logo.png",
  },
  {
    id: 5,
    title: "AUTO OWNERS",
    link: "https://www.auto-owners.com/customer-claims#.VqkZxiorKUm",
    image:
      "https://ww1.freelogovectors.net/wp-content/uploads/2013/06/Auto-Owners-Insurance-Logo.jpg?lossy=1&w=2560&ssl=1",
  },
  {
    id: 6,
    title: "CHUBB",
    link: "https://www.chubb.com/us-en/claims/",
    image:
      "https://www.pngitem.com/pimgs/m/27-278976_chubb-logo-chubb-insurance-logo-hd-png-download.png",
  },
  {
    id: 7,
    title: "CINCINNATI",
    link: "https://www.cinfin.com/about-us",
    image: "https://companieslogo.com/img/orig/CINF-c91f0536.png?t=1597168017",
  },
  {
    id: 8,
    title: "CONCORD",
    link: "https://www.concordgroupinsurance.com/contact-us/claimsreporting",
    image:
      "https://www.fapeabody.com/wp-content/uploads/2016/03/Concord-high-Res-Cplor-logo-300-dpi-06-2014-300x193.jpg",
  },
  {
    id: 9,
    title: "COUNTRY FINANCIAL",
    link: "https://www.countryfinancial.com/",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Country_Financial_logo.svg/2560px-Country_Financial_logo.svg.png",
  },
  {
    id: 10,
    title: "ERIE",
    link: "https://www.erieinsurance.com/ReportAClaim/default.aspx",
    image:
      "https://www.pngkey.com/png/full/209-2098410_erie-insurance-logo.png",
  },
  {
    id: 11,
    title: "FARM BUREAU",
    link: "https://www.farmbureauinsurance-mi.com/Claims/Home_Office_Departments/",
    image:
      "https://www.hom.org/wp-content/uploads/2020/01/farm-bureau-insurance-PNG.png",
  },
  {
    id: 12,
    title: "FARMERS",
    link: "https://www.farmers.com/claims/",
    image:
      "https://cdn.worldvectorlogo.com/logos/farmers-insurance-group-logo.svg",
  },
  {
    id: 13,
    title: "FIREMAN'S FUND",
    link: "https://www.agcs.allianz.com/claims/report-a-claim.html",
    image:
      "https://programbusiness.com/wp-content/uploads/2013/09/Firemans_Fund.jpg",
  },
  {
    id: 14,
    title: "GEICO",
    link: "https://claims.geico.com/ReportClaim.aspx",
    image:
      "https://1000logos.net/wp-content/uploads/2018/08/logo-geico-insurance.jpg",
  },
  {
    id: 15,
    title: "GRANGE",
    link: "https://www.grangeinsurance.com/claims/report-or-track-a-claim",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Grange_Insurance_logo.svg/1280px-Grange_Insurance_logo.svg.png",
  },
  {
    id: 16,
    title: "THE HANOVER GROUP",
    link: "https://www.hanover.com/claims.html",
    image: "https://logonoid.com/images/hanover-insurance-logo.jpg",
  },
  {
    id: 17,
    title: "THE HARTFORD",
    link: "https://www.thehartford.com/service/",
    image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/0/0b/The_Hartford_Financial_Services_Group_logo.svg/1200px-The_Hartford_Financial_Services_Group_logo.svg.png",
  },
  {
    id: 18,
    title: "HOMEOWNERS CHOICE",
    link: "https://hcpci.com/claims/",
    image: "https://hcigroup.com/wp-content/uploads/2021/07/HCPCI.png",
  },
  {
    id: 19,
    title: "LEXINGTON",
    link: "https://www.lexingtoninsurance.com/claims",
    image:
      "https://www.nicepng.com/png/full/356-3563402_lexington-insurance-member-of-aig-logo-lexington-insurance.png",
  },
  {
    id: 20,
    title: "LIBERTY MUTUAL",
    link: "https://www.libertymutual.com/claims-center",
    image:
      "https://1000logos.net/wp-content/uploads/2020/09/Liberty-Mutual-Logo.png",
  },
  {
    id: 21,
    title: "METLIFE",
    link: "https://www.metlife.com/support-and-manage/file-a-claim/",
    image:
      "https://www.pngall.com/wp-content/uploads/5/MetLife-Logo-Background-PNG-Image.png",
  },
  {
    id: 22,
    title: "MMG",
    link: "https://www.mmgins.com/claim-reporting/report-claim/",
    image:
      "https://recruiting.ultipro.com/MMG1000MMGIC/JobBoard/63c9a0d6-63a0-4190-9bc3-1cb0136e7549/Styles/GetLargeHeaderLogo?brandId=6719c30d-84ef-4198-89f0-c0d668af06f5&m=636728769315790000",
  },
  {
    id: 23,
    title: "NATIONAL IDEMNITY",
    link: "https://www.nationalindemnity.com/Claims.aspx",
    image:
      "https://www.allprorisk.com/wp-content/uploads/2017/11/national-indemnity-logo-seattle-wa-insurance.png",
  },
  {
    id: 24,
    title: "NATIONWIDE",
    link: "https://www.nationwide.com/start-insurance-claim.jsp",
    image:
      "https://www.nicepng.com/png/detail/188-1885034_previous-logo-nationwide-insurance-white-logo.png",
  },
  {
    id: 25,
    title: "NORTH STAR",
    link: "https://northstarmutual.com/report-claim",
    image:
      "https://chambermaster.blob.core.windows.net/images/customers/499/members/1252/logos/MEMBER_LOGO/NS-logo-Color.png",
  },
  {
    id: 26,
    title: "OHIO CASUALTY",
    link: "https://www.ohiocasualty-ins.com/claims/",
    image:
      "https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v1397752211/3d49f71530b20a5f6583f3bd5613f583.png",
  },
  {
    id: 27,
    title: "PEERLESS",
    link: "https://www.peerless-ins.com/claims/",
    image:
      "https://mlxwx3bywoz1.i.optimole.com/cb:mosB.34a81/w:600/h:233/q:auto/ig:avif/https://www.stahlka.com/wp-content/uploads/sites/1255/2018/04/Peerless-Insurance-logo.png",
  },
  {
    id: 28,
    title: "PROGRESSIVE",
    link: "https://www.progressive.com/contact-us/",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Logo_of_the_Progressive_Corporation.svg/2560px-Logo_of_the_Progressive_Corporation.svg.png",
  },
  {
    id: 29,
    title: "PRUDENTIAL",
    link: "https://www.prudential.com/contact-us",
    image:
      "https://seeklogo.com/images/P/prudential-logo-FC4B9359F3-seeklogo.com.png",
  },
  {
    id: 30,
    title: "RURAL MUTUAL",
    link: "https://www.ruralmutual.com/report-claim/",
    image:
      "https://mma.prnewswire.com/media/708611/Rural_Mutual_Insurance_Company_Logo.jpg?p=twitter",
  },
  {
    id: 31,
    title: "SAFECO",
    link: "https://www.safeco.com/claims",
    image:
      "https://www.pngitem.com/pimgs/m/20-204938_safeco-insurance-hd-png-download.png",
  },
  {
    id: 32,
    title: "STATE AUTO",
    link: "https://www.stateauto.com/mypolicy/page.aspx?id=512",
    image:
      "https://www.smartinsuranceus.com/user_files/carrier_images/StateAuto19.png",
  },
  {
    id: 33,
    title: "STATEFARM",
    link: "https://www.statefarm.com/insurance/home-and-property/claims",
    image:
      "https://www.seekpng.com/png/detail/259-2592206_state-farm-insurance-logo.png",
  },
  {
    id: 34,
    title: "TRAVELERS",
    link: "https://www.statefarm.com/insurance/home-and-property/claims",
    image:
      "https://purepng.com/public/uploads/large/purepng.com-travelers-logologobrand-logoiconslogos-251519939802jz920.png",
  },
  {
    id: 35,
    title: "USAA",
    link: "https://www.usaa.com/inet/ent_logon/Logon",
    image:
      "https://seeklogo.com/images/U/usaa-logo-E52E11D96A-seeklogo.com.png",
  },
  {
    id: 36,
    title: "WESTBEND",
    link: "https://www.thesilverlining.com/claims/how-to-report-a-claim",
    image:
      "https://media-s3-us-east-1.ceros.com/west-bend-mutual/images/2019/11/19/c7fa201e01c450bf864ba1530d6c5109/wblogoshort-color.png",
  },
];
