import React from "react";
import "./RoofSystem.scss";
import RightImageCardBtn from "../../../components/newRoofSessions/rightImageCardBtn/RightImageCardBtn";
import LeftImgCardBtn from "../../../components/newRoofSessions/leftImgCardBtn/LeftImgCardBtn";
import IMG1 from "../../../assets/ruff/ruf1.png";
import IMG2 from "../../../assets/ruff/ruf2.png";
import IMG3 from "../../../assets/ruff/ruf3.png";
import { Helmet } from "react-helmet";
export default function RoofSystem() {
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <RightImageCardBtn
        img={IMG1}
        title="The GAF Lifetime Roofing System"
        desp="Install any GAF Lifetime Shingle & at least 3 qualifying GAF Accessories, and you'll automatically get a Lifetime Ltd. warranty on your shingles and all qualifying GAF accessories! PLUS Non-prorated coverage for the first 10 years! To get even stronger coverage, choose a System Plus or Golden Pledge Ltd. Warranty."
        btnTxt="Compare Warranties"
        link="/warrenty"
      />
      <LeftImgCardBtn
        img={IMG2}
        title="GAF Virtual Home Remodeler"
        desp="Homeowners face a number of challenges when remodeling their homes. One of the most common problems they encounter is choosing the material that will complement their home's architectural style. If you've considered upgrading your roof but have found yourself overwhelmed with the options, you can turn to GAF's Virtual Home Remodeler. Whether you have a traditional or contemporary design, you're sure to find the best GAF roofing options from their wide selection of shingle types and colors. You can even get a sneak peek of your new roof before installing it using GAF's revolutionary app."
        btnTxt="Open Visualizer"
        link="/virtual-remodeling"
      />
      <RightImageCardBtn
        img={IMG3}
        title="Keep your Attic Cool in the Summer"
        desp="During the warmer months, using GAF attic fans not only cools the air in your house but also the structure and contents. This helps cool the home and attic by up to 30 degrees and sometimes more, allowing them to stay much cooler the following day (the principle of Thermal Mass Cooling). So overall costs to keep the home cool are dramatically reduced, especially as compared to air conditioning."
        btnTxt="View More"
        link="/attic-vent"
      />
    </div>
  );
}
