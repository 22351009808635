import React from "react";
import "./AboutVideo.scss";
import BackVideo from "../../../assets/video/vidCrop.mp4";

export default function AboutVideo() {
  return (
    <div className="videoAboutBase">
      <div className="videoAboutContent">
        <div className="bottomDivAbout">
          <video className="videoTagAboutBottom" controls>
            <source src={BackVideo} type="video/mp4" />
          </video>
        </div>
        <div className="videoFlexDespAbout">
          <p className="videoSubTxtAbout">
            Customer satisfaction is our top priority. You can trust that your
            next project will be completed correctly and on time, because at
            Restoration, Roofing & Remodeling, we've got you covered.
          </p>
        </div>
      </div>
    </div>
  );
}
