import React, { useState } from "react";
import "./StormService.scss";
import TopButtons from "../../../components/stormServiceSessions/topButtons/TopButtons";
import TopView from "../../../components/serviceSessions/topView/TopView";
import TopCard from "../../../components/serviceSessions/topCard/TopCard";
import BottomCard from "../../../components/serviceSessions/bottomCard/BottomCard";
import StormCard from "../../../components/stormServiceSessions/stormCard/StormCard";
import InsuranceCard from "../../../components/stormServiceSessions/insuranceCard/InsuranceCard";
import { array } from "../../../assets/data/Insurance";
import InsuraneDetails from "../../../components/stormServiceSessions/insuranceDetails/InsuraneDetails";
import Preventive from "../../../components/stormServiceSessions/preventive/Preventive";
import { Helmet } from "react-helmet";
export default function StormService() {
  const [changePage, setChangePage] = useState(0);

  const [show, setShow] = useState(true);
  const [id, setId] = useState(0);
  const TopViewDesp = () => {
    return (
      <>
        <p style={{ fontWeight: "600", fontSize: "16px" }}>
          WE ARE STORM DAMAGE SPECIALISTS. WE HAVE A CLAIMS SPECIALIST ON STAFF
          TO ASSIST YOU.
        </p>
        <p>
          Hail and wind damage can be devastating to your home. Your roofing,
          siding, windows, and gutters can get dented or even ripped off of your
          structure.
        </p>
        <p>
          If you have experienced storm damage to your property, don't wait to
          call for an inspection. Protect your largest investment.
        </p>
      </>
    );
  };
  const TopInsDesp = () => {
    return (
      <>
        <p style={{ fontWeight: "600", fontSize: "16px" }}>
          Below are links to several major insurance companies and their claim
          forms.
        </p>
        <p>
          The insurance information provided below in no way implies any
          affiliation between Restoration Roofing & Remodeling and these
          companies.
        </p>
      </>
    );
  };

  const data = {
    desp: <TopViewDesp />,
    desp1: <TopInsDesp />,
    services: [
      {
        id: 0,
        title: "AAA",
        link: "https://chicago.aaa.com/insurance/accident_assist.aspx?",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/American-Automobile-Association-Logo.svg/1200px-American-Automobile-Association-Logo.svg.png",
      },
      {
        id: 1,
        title: "AIG",
        link: "https://www.aig.com/individual/individual-claims",
        image: "https://1000logos.net/wp-content/uploads/2021/04/AIG-logo.png",
      },
      {
        id: 2,
        title: "ALLSTATE",
        link: "https://www.allstate.com/claims.aspx",
        image: "https://www.pngmart.com/files/13/Allstate-Logo-PNG-Pic.png",
      },
      {
        id: 3,
        title: "AMERICAN FAMILY",
        link: "https://www.amfam.com/claims/default.asp",
        image:
          "https://1000logos.net/wp-content/uploads/2022/12/American-Family-Insurance-logo.png",
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <TopButtons changePage={changePage} setChangePage={setChangePage} />
      {changePage === 0 && (
        <>
          <TopView
            heroImg="https://media.angi.com/s3fs-public/asphalt-shingles-home-roofing.jpg"
            title="WE'VE GOT YOU COVERED"
            desp={data.desp}
          />
          <TopCard
            cardImg="https://www.travelers.com/iw-images/resources/Weather/Medium/HailProtection_Medium.jpg"
            title="Roof Damage from Hail or Wind"
            txt1="You may have been in your home or business during a hail storm at some point. It sounds like someone is dropping rocks on your roof. Essentially, that is pretty close to what is happening. Some hail can be the size of small boulders (softball-sized). Hail can cause shingles to dent or even leave craters in the shingles. Combined with high winds, shingles can be ripped off a home. You may have seen shingles around your property after a heavy hail or wind storm. This type of damage can not go unchecked. Missing shingles can lead to water seeping into your roof or even your home. Do your property a favor, fix small ones before they become larger issues."
            txt2="Even without these obvious signs, you may have damage and not know it! Most wind and hail damages cannot be seen from ground level and go undetected to the untrained eye. If your roof isn't leaking right now, it may leak soon and you don't want to take that risk."
          />
          <StormCard data={data.services} setChangePage={setChangePage} />
          <BottomCard
            cardImg="https://images.pexels.com/photos/45842/clasped-hands-comfort-hands-people-45842.jpeg?cs=srgb&dl=pexels-pixabay-45842.jpg&fm=jpg"
            title="Trust us to handle it"
            txt1="When you have hail or wind damage, you can depend on our team to provide a comprehensive service. We're equipped to handle all of your storm-related repair needs."
            txt2="When you need fast, high-quality repairs, we'll be there. We stand behind our work. To us, customer satisfaction is what comes first. Expect exceptional service."
          />
        </>
      )}
      {changePage === 1 && (
        <>
          <TopView
            heroImg="https://media.angi.com/s3fs-public/asphalt-shingles-home-roofing.jpg"
            title="Insurance Links"
            desp={data.desp1}
          />
          <InsuraneDetails
            setId={setId}
            id={id}
            setShow={setShow}
            show={show}
          />
          <InsuranceCard
            data={array}
            setId={setId}
            id={id}
            setShow={setShow}
            show={show}
          />
        </>
      )}

      {changePage === 2 && (
        <>
          <Preventive />
        </>
      )}
    </div>
  );
}
