import React, { useState } from "react";
import "./Warrenty.scss";
import {
  timberLine,
  tab_shingles,
  designer,
  multi_family,
} from "../../../assets/data/Warrenty";

import WarrentyPopup from "../../../components/popup/warrenty/WarrentyPopup";
import { Helmet } from "react-helmet";
export default function Warrenty() {
  const [show, setShow] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [id, setId] = useState(0);

  const togglePopup = () => {
    setPopupShow(!popupShow);
  };

  const TOP_IMG_LIST = ({ data }) => {
    return (
      <div className="imgTopBase">
        {data.map((data) => (
          <div
            className="imgTopCOntent"
            key={data.id}
            onClick={() => {
              setPopupData(data);
              togglePopup();
            }}
          >
            <img className="dataIMgTop" src={data.img} alt="ss" />
            {data.title}
          </div>
        ))}
      </div>
    );
  };
  const TIMBERLINE = () => {
    return (
      <>
        <TOP_IMG_LIST data={timberLine} />
        <p className="ans1">
          Qualifying Shingles: Timberline HDZ Shingles, Timberline HD Shingles,
          Timberline HDZ RS+ Shingles, Timberline HDZ RS Shingles, Timberline NS
          Shingles, Timberline AH Shingles, Timberline AS II Shingles,
          Timberline CS Shingles
        </p>

        <ul>
          <li>
            <p className="ans1">
              Contractors enrolled in GAF factory-certified contractor programs
              are independent contractors, not employees or agents of GAF.
            </p>
          </li>
          <li>
            <p className="ans1">
              Definition of Lifetime: The word Lifetime means as long as you,
              the original owner(s) [or the second owner(s) if coverage was
              properly transferred during the Smart Choice Protection Period],
              own the property where the shingles and/or accessories are
              installed. The Lifetime warranty is applicable only to shingles
              and accessories installed on a single-family detached residence
              owned by individuals. For any other type of owner or building,
              such as a corporation, governmental entity, religious entity,
              condominium or homeowner association, school, apartment building,
              office building, or multi-use structure, the length of the
              warranty is 40 years.
            </p>
          </li>
          <li>
            <p className="ans1">
              Special Installation: Your GAF Layer Lock-labeled Shingles will be
              covered up to the maximum wind speed above only if installed using
              4 nails per shingle and you have GAF Starter Strip Products
              installed at the eaves and rakes. Special Installation for all
              other GAF Shingles requires use of 6 nails per shingle and GAF
              Starter Strip Products installed at the eaves and rakes. Your GAF
              Ridge Cap Shingles will be covered up to the maximum wind speed
              above only if your ridge cap shingles are installed in strict
              accordance with the Maximum Wind Speed Coverage Under Limited
              Warranty section of the applicable ridge cap shingle application
              instructions. For installations not eligible for the WindProven
              Limited Wind Warranty, Maximum Wind Speed Coverage is 130mph with
              Special Installation or 110mph without Special Installation.
            </p>
          </li>
          <li>
            <p className="ans1">
              For installations not eligible for the WindProven Limited Wind
              Warranty, Maximum Wind Speed Coverage is 130mph with Special
              Installation or 110mph without Special Installation.
            </p>
          </li>
        </ul>
      </>
    );
  };
  const TAB_SINGLES = () => {
    return (
      <>
        <TOP_IMG_LIST data={tab_shingles} />
        <p className="ans1">
          Qualifying Shingles: Marquis WeatherMax Shingles, Royal Sovereign
          Shingles
        </p>

        <ul>
          <li>
            <p className="ans1">
              GAF Accessories covered under this limited warranty include: GAF
              Ridge Cap Shingles, GAF Starter Strip Shingles, GAF Leak Barrier
              Products, GAF Roof Deck Protection Products, and GAF Cobra
              Ventilation Products. This limited warranty does not cover
              low-slope membranes, Master Flow Ventilation Products, or
              ShingleMatch Roof Accessory Paint. Please go to gaf.com for a copy
              of the limited warranties covering these products.
            </p>
          </li>
          <li>
            <p className="ans1">
              Contractors enrolled in GAF factory-certified contractor programs
              are independent contractors, not employees or agents of GAF.
            </p>
          </li>
          <li>
            <p className="ans1">
              Smart Choice Protection Period: refers to the crucial period of
              time following installation of the GAF Products during which the
              coverage provided for in this limited warranty is non-prorated.
              After the Smart Choice Protection Period specified above, the
              remedy provided for in this warranty may be different than that
              provided for during the Smart Choice Protection Period, and any
              remedy will be reduced to reflect the use you have received from
              your GAF Products. The amount of use will be calculated by
              dividing the number of months which have elapsed since
              installation to the date of claim by the number of months in the
              warranty term.
            </p>
          </li>
        </ul>
      </>
    );
  };
  const DESIGNER = () => {
    return (
      <>
        <TOP_IMG_LIST data={designer} />
        <p className="ans1">
          Qualifying Shingles: Timberline UHD Shingles, Timberline UHD RS
          Shingles, Camelot II Shingles, Glenwood Shingles, Grand Canyon
          Shingles, Grand Sequoia Shingles, Grand Sequoia AS Shingles, Grand
          Sequoia RS+ Shingles, Grand Sequoia RS Shingles, Slateline Shingles,
          Woodland Shingles
        </p>

        <ul>
          <li>
            <p className="ans1">
              GAF Accessories covered under this limited warranty include: GAF
              Ridge Cap Shingles, GAF Starter Strip Shingles, GAF Leak Barrier
              Products, GAF Roof Deck Protection Products, and GAF Cobra
              Ventilation Products. This limited warranty does not cover
              low-slope membranes, Master Flow Ventilation Products, or
              ShingleMatch Roof Accessory Paint. Please go to gaf.com for a copy
              of the limited warranties covering these products.
            </p>
          </li>
          <li>
            <p className="ans1">
              Contractors enrolled in GAF factory-certified contractor programs
              are independent contractors, not employees or agents of GAF.
            </p>
          </li>
          <li>
            <p className="ans1">
              Definition of Lifetime: The word Lifetime means as long as you,
              the original owner(s) [or the second owner(s) if coverage was
              properly transferred during the Smart Choice Protection Period],
              own the property where the shingles and/or accessories are
              installed. The Lifetime warranty is applicable only to shingles
              and accessories installed on a single-family detached residence
              owned by individuals. For any other type of owner or building,
              such as a corporation, governmental entity, religious entity,
              condominium or homeowner association, school, apartment building,
              office building, or multi-use structure, the length of the
              warranty is 40 years.
            </p>
          </li>
          <li>
            <p className="ans1">
              Smart Choice Protection Period: refers to the crucial period of
              time following installation of the GAF Products during which the
              coverage provided for in this limited warranty is non-prorated.
              After the Smart Choice Protection Period specified above, the
              remedy provided for in this warranty may be different than that
              provided for during the Smart Choice Protection Period, and any
              remedy will be reduced to reflect the use you have received from
              your GAF Products. The amount of use will be calculated by
              dividing the number of months which have elapsed since
              installation to the date of claim by the number of months in the
              warranty term. For a Lifetime warranty, the number of months in
              the warranty term is deemed to be 600 for years 11-40 of the
              warranty term. For years 41 and beyond of a Lifetime warranty,
              GAF's contribution is 20%.
            </p>
          </li>
          <li>
            <p className="ans1">
              Special Installation: Your GAF Shingles will be covered up to the
              maximum wind speed above only if your shingles are installed using
              6 nails per shingle and you have GAF Starter Strip Products
              installed at the eaves and rakes. Your GAF Ridge Cap Shingles will
              be covered up to the maximum wind speed above only if your ridge
              cap shingles are installed in strict accordance with the Maximum
              Wind Speed Coverage Under Ltd. Warranty section of the applicable
              ridge cap shingle application instructions. Maximum Wind Speed
              Coverage without Special Installation is 110mph.
            </p>
          </li>
        </ul>
      </>
    );
  };
  const MULTI_FAMILY = () => {
    return (
      <>
        <TOP_IMG_LIST data={multi_family} />
        <p className="ans1">
          Qualifying Shingles: Timberline HDZ Shingles, Timberline HD Shingles,
          Timberline HDZ RS+ Shingles, Timberline HDZ RS Shingles, Timberline NS
          Shingles, Timberline AH Shingles, Timberline AS II Shingles,
          Timberline CS Shingles
        </p>

        <ul>
          <li>
            <p className="ans1">
              GAF Accessories covered under this limited warranty include: GAF
              Ridge Cap Shingles, GAF Starter Strip Shingles, GAF Leak Barrier
              Products, GAF Roof Deck Protection Products, and GAF Cobra
              Ventilation Products. This limited warranty does not cover
              low-slope membranes, Master Flow Ventilation Products, or
              ShingleMatch Roof Accessory Paint. Please go to gaf.com for a copy
              of the limited warranties covering these products.
            </p>
          </li>
          <li>
            <p className="ans1">
              Contractors enrolled in GAF factory certified contractor programs
              are independent contractors, not employees or agents of GAF.
            </p>
          </li>
          <li>
            <p className="ans1">
              Smart Choice Protection Period: refers to the crucial period of
              time following installation of the GAF Products during which the
              coverage provided for in this limited warranty is non-prorated.
              After the Smart Choice Protection Period specified above, the
              remedy provided for in this warranty may be different than that
              provided for during the Smart Choice Protection Period, and any
              remedy will be reduced to reflect the use you have received from
              your GAF Products. The amount of use will be calculated by
              dividing the number of months which have elapsed since
              installation to the date of claim by the number of months in the
              warranty term. For a Lifetime warranty, the number of months in
              the warranty term is deemed to be 600 for years 11-40 of the
              warranty term. For years 41 and beyond of a Lifetime warranty,
              GAF's contribution is 20%.
            </p>
          </li>
          <li>
            <p className="ans1">
              Special Installation: Your GAF Layer Lock-labeled Shingles will be
              covered up to the maximum wind speed above only if installed using
              4 nails per shingle and you have GAF Starter Strip Products
              installed at the eaves and rakes. Special Installation for all
              other GAF Shingles requires use of 6 nails per shingle and GAF
              Starter Strip Products installed at the eaves and rakes. Your GAF
              Ridge Cap Shingles will be covered up to the maximum wind speed
              above only if your ridge cap shingles are installed in strict
              accordance with the Maximum Wind Speed Coverage Under Limited
              Warranty section of the applicable ridge cap shingle application
              instructions. For installations not eligible for the WindProven
              Limited Wind Warranty, Maximum Wind Speed Coverage is 130mph with
              Special Installation or 110mph without Special Installation.
            </p>
          </li>
        </ul>
      </>
    );
  };

  const array = [
    {
      id: 0,
      title: "TIMBERLINE SHINGLES",
      desp1: <TIMBERLINE />,
    },
    {
      id: 1,
      title: "3-TAB SHINGLES",
      desp1: <TAB_SINGLES />,
    },
    {
      id: 2,
      title: "DESIGNER SHINGLES",
      desp1: <DESIGNER />,
    },
    {
      id: 3,
      title: "FOR COMMERCIAL MULTI-FAMILY PROPERTIES TIMBERLINE SHINGLES",
      desp1: <MULTI_FAMILY />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <div className="roofListBase1">
        <div className="roofListContent1">
          <p className="detailsTitle">Warranty Information</p>
          {array.map((data, index) => (
            <>
              <button
                key={data.id}
                className="Questions "
                onClick={() => {
                  setId(index);
                  if (index === id) {
                    setShow(!show);
                  } else {
                    setShow(true);
                  }
                }}
              >
                <p className="qtn">{data.title}</p>
                <img
                  style={{
                    transform:
                      show && id === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 1.0s",
                  }}
                  src="https://static.vecteezy.com/system/resources/previews/006/827/566/non_2x/down-arrow-icon-sign-symbol-logo-vector.jpg"
                  alt="btn"
                  className="arrow"
                />
              </button>

              {show && id === index && (
                <div className="insranceDetailsDiv">
                  <p className="ans">{data.desp1}</p>
                </div>
              )}
            </>
          ))}
          {popupShow && (
            <WarrentyPopup closePopup={togglePopup} data={popupData} />
          )}
        </div>
      </div>
    </>
  );
}
