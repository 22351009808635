import React from "react";
import "./Video.scss";
import { array } from "../../assets/data/Videos";
import { Helmet } from "react-helmet";

export default function Video() {
  return (
    <>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <div className="videosBase">
        <div className="videosContent">
          <div className="topVid">
            <a href="https://www.youtube.com/@RRRHoustonTX/videos">
              <button className="chanelTitle">
                Subscribe to our youtube channel
              </button>
            </a>
          </div>
          <div className="videosCardBase">
            {array.map((data) => (
              <div className="videosCard">
                <div className="videosCardData">
                  <div className="videosCardImg">
                    <video className="videosImg" controls>
                      <source src={data.video} type="video/mp4" />
                    </video>
                  </div>
                  <div className="videosCardContent">
                    <p className="videosTitleTxt">{data.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
