import React from "react";
// import "./Roofing.scss";
import RoofingTop from "../../components/roofingSessions/top/RoofingTop";
import CardTop from "../../components/roofingSessions/cardTop/CardTop";
import CardMiddle from "../../components/roofingSessions/cardMiddle/CardMiddle";
import CardBottom from "../../components/roofingSessions/cardBottom/CardBottom";
import IMG1 from "../../assets/data/help4.png";
import IMG2 from "../../assets/data/help3.png";
import IMG3 from "../../assets/data/help2.jpg";
import IMG4 from "../../assets/data/help1.png";
import HelpCard from "../../components/roofingSessions/helpCard/HelpCard";
import { Helmet } from "react-helmet";
import CommercialTop from "../../components/commercialRoofing/CommercialTop";
import CommercialMiddle from "../../components/commercialRoofing/CommercialMiddle";
import CommercialBottom from "../../components/commercialRoofing/CommercialBottom";
export default function Commercial() {
  const array = [
    {
      id: 1,
      image: IMG1,
      txt: "View shingle styles and colors on a picture of your house or one like yours.",
      link: "/virtual-remodeling",
      btnTxt: "Picture Your New Roof",
    },
    {
      id: 2,
      image: IMG2,
      txt: "See everything that goes into the roof over your head. Spoiler alert: It's a lot more than shingles.",
      link: "/roof-system",
      btnTxt: "What Goes Into a Roof",
    },
    {
      id: 3,
      image: IMG3,
      txt: "Compare warranties with our GAF Limited Warranty Comparison Guide.",
      link: "/warrenty",
      btnTxt: "Selecting Your Warranty",
    },
    {
      id: 4,
      image: IMG4,
      txt: "Guidance on roof inspection and how to spot signs of damage.",
      link: "/new-roof",
      btnTxt: "Do I Need A New Roof",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Top roofing contractor near Texas | Roofing Installation </title>
        <meta
          name="description"
          content="Top roofing contractor near Texas. Complete roof replacement in Spring. Get best Roofing work near Texas and choose best choice for roofing installation in Spring."
        />
      </Helmet>
      <CommercialTop />
      <CommercialMiddle />
      <CommercialBottom />
    </div>
  );
}
