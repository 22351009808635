import React from "react";
import "./AboutCard.scss";
import IMG from "../../../assets/about/about2.png";
export default function AboutCard() {
  const reviewUrl = "https://www.google.com/search?q=Restoration+Roofing+%26+Remodeling+LLC&sca_esv=cbee88a0659955a3&sca_upv=1&rlz=1C1GCEA_enIN1033IN1033&sxsrf=ADLYWIKmsSb4UL5dyTP440vSg4BbgptqNQ%3A1719492642511&ei=ImB9ZuXqHqbgseMPy4uQ-A4&ved=0ahUKEwjlg5qh6fuGAxUmcGwGHcsFBO8Q4dUDCBA&uact=5&oq=Restoration+Roofing+%26+Remodeling+LLC&gs_lp=Egxnd3Mtd2l6LXNlcnAiJFJlc3RvcmF0aW9uIFJvb2ZpbmcgJiBSZW1vZGVsaW5nIExMQzIFEAAYgAQyBhAAGBYYHjILEAAYgAQYhgMYigUyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKMfUO4KWMUdcAJ4AZABAJgB5gGgAeYBqgEDMi0xuAEDyAEA-AEB-AECmAIDoALvAagCEcICBxAjGCcY6gLCAhoQLhiABBjjBBi0AhjHARjpBBjqAhivAdgBAcICFBAAGIAEGOMEGLQCGOkEGOoC2AEBwgIWEAAYAxi0AhjlAhjqAhiMAxiPAdgBApgDBroGBggBEAEYAboGBggCEAEYC5IHBTIuMC4xoAf-BA&sclient=gws-wiz-serp#ip=1&lrd=0x8647337550c94b33:0x7e7a7b98d49ddbdc,1,,,,";

  return (
    <div className="cardAboutBase">
      <div className="cardAboutContent">
        <div className="cardAboutLeftGrid">
          <div className="imgAboutBase">
            <img src={IMG} alt="cardImg" className="cardAboutImg" />
          </div>
        </div>
        <div className="cardAboutRightGrid">
          <div className="textAboutBase">
            <p className="cardAboutTxt">
              The RRR team did a wonderful job remodeling and painting our new
              home and working in a short span of time. We would use them again
              and highly recommend! Very knowledgeable building professionals on
              the jobsite , we had a lot of confidence in their skill and
              competencies.
            </p>

            <p className="cardAboutTxt">Diane K.</p>
            <p className="cardAboutSubTxt">A happy customer</p>
            <p className="cardAboutTxt">
              Richard was able to do a lst minute urgent inspection for one of
              my customers and have the needed repair scheduled and completed by
              the following day. Highly recommend for any roofing repair and
              replacement needs.
            </p>

            <p className="cardAboutTxt">Cory G.</p>
            <p className="cardAboutSubTxt">A happy customer</p>
            <a
              href={reviewUrl}
              style={{ textDecoration: "none" }}
            >
              {/* <a href="https://www.angi.com/companylist/us/tx/spring/restoration-roofing-and-remodeling-llc-reviews-9943150.htm" style={{ textDecoration: 'none' }}> */}
              <button className="blueBtn" style={{ marginTop: "20px" }}>
                See what our cutomers have to say
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
