import React from "react";
import "./StormCard.scss";
export default function StormCard(props) {
  return (
    <div className="roofListBase">
      <div className="roofListContent">
        <div className="toproofList">
          <p className="roofListTitle">Insurance Links</p>
          <p style={{ margin: "0px" }}>
            Below are links to several major insurance companies and their claim
            forms.
          </p>
        </div>
        <div className="contentDataroofList">
          {props.data.map((data) => (
            <a href={data.link} className="cardDataroofLIst" key={data.id}>
              <div className="cardContentroofList">
                <div className="shadowroofList"></div>
                <img src={data.image} alt="roof" className="roofListIMages" />
                <p className="txtInroofCard">{data.title}</p>
              </div>
            </a>
          ))}
        </div>
        <div className="viewMoreinsBtnVid">
          <button
            onClick={() => {
              props.setChangePage(1);
            }}
            className="blueBtn"
          >
            VIEW MORE
          </button>
        </div>
      </div>
    </div>
  );
}
