import React from "react";
import IMG1 from "../../../assets/svc/patio/1.jpeg";
import IMG2 from "../../../assets/svc/patio/2.jpeg";
import IMG3 from "../../../assets/svc/patio/3.jpeg";
import IMG4 from "../../../assets/svc/patio/4.jpeg";
import IMG5 from "../../../assets/svc/patio/5.jpeg";
import IMG6 from "../../../assets/svc/patio/6.jpeg";
import IMG8 from "../../../assets/svc/carpet-tile/1.jpeg";
import IMG9 from "../../../assets/svc/carpet-tile/2.jpeg";
import IMG10 from "../../../assets/svc/carpet-tile/3.jpeg";
import IMG11 from "../../../assets/svc/carpet-tile/4.jpeg";
import IMG12 from "../../../assets/svc/carpet-tile/5.jpeg";
import IMG13 from "../../../assets/svc/carpet-tile/6.jpeg";
import IMG14 from "../../../assets/svc/carpet-tile/7.jpeg";

import Service from "../Service";
import { Helmet } from "react-helmet";

export default function RemodelingCompany() {
  const data = {
    houston: {
      heroImg:
        "https://cdn.pixabay.com/photo/2023/09/15/09/08/ai-generated-8254412_1280.jpg",
      title: "Remodeling Company in Houston, TX",
      desp: "Transform Your Home with Professional Remodeling Company in Houston, TX",
      cardTxt1:
        "Restoration Roofing & Remodeling is a reliable and professional remodeling company in Houston, TX. We have been providing quality restoration services to the community for over 10 years. Our team of experienced and qualified professionals are committed to providing you with the best possible service. We offer a wide range of restoration services, including roofing, remodeling, repair, and replacement. We use the latest technologies and methods to ensure that your project is completed quickly and effectively. Our team is knowledgeable about all types of restoration projects, so you can be sure that your home will look great after we finish it.",
      cardTxt2:
        "Whether you are looking for simple updates or comprehensive renovations, Restoration Roofing & Remodeling has the resources and experience needed to make your home look its best. Contact us today to schedule a consultation!",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
        {
          id: 13,
          img: IMG13,
        },
        {
          id: 14,
          img: IMG14,
        },
      ],
    },
    spring: {
      heroImg:
        "https://cdn.pixabay.com/photo/2023/09/15/09/08/ai-generated-8254412_1280.jpg",
      title: "Remodeling Company in Spring, TX",
      desp: "Transform Your Home with Professional Remodeling Company in Spring, TX",
      cardTxt1:
        "A home is one of the most important investments you will ever make. It?s not only your place to relax and call your own, but it?s also a reflection of you and your lifestyle. So why wouldn?t you want it to look its best? Restoration Roofing & Remodeling is a professional remodeling company in Spring, TX that offers a wide variety of services to home and business owners in the area. From roof replacement and repair to gutter installation and repairs, Restoration is able to provide comprehensive service to homeowners and businesses alike.",
      cardTxt2:
        "Whether you are looking for simple updates or comprehensive renovations, Restoration Roofing & Remodeling has the resources and experience needed to make your home look its best. Contact us today to schedule a consultation!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
        {
          id: 13,
          img: IMG13,
        },
        {
          id: 14,
          img: IMG14,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
