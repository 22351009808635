import React from "react";
import "./LeftImgCardBtn.scss";
import { Link } from "react-router-dom";
export default function LeftImgCardBtn(props) {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={props.img} alt="cardImg" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">{props.title}</p>
            <p className="cardSubTxt">{props.desp}</p>
            <Link to={props.link} style={{ textDecoration: "none" }}>
              <button className="blueBtn">{props.btnTxt}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
