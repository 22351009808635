import React from "react";
import "./ScheduleSession.scss";
import HOM from "../../../assets/logos/hom.png";
import { Link } from "react-router-dom";

export default function ScheduleSession() {
  return (
    <div className="scheduleBase">
      <div className="leftAbsolute"></div>
      <div className="rightAbsolute"></div>

      <div className="scheduleContent">
        <div className="sceduleLeft">
          <div className="dataSchedule">
            <p className="scheduleTitleTxt">Schedule Free Inspection</p>
            <p className="scheduleSubTxt">
              All inspections are free and flexible according to schedule
              availability
            </p>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/contact-us"
            >
              <button className="whiteBtnS">SCHEDULE NOW</button>
            </Link>
          </div>
        </div>
        <div className="sceduleRight">
          <div className="sceduleArrow">
            <div className="sideArrow"></div>
          </div>
          <div className="sceduleImage">
            <img src={HOM} alt="img" className="rightLogo" />
          </div>
        </div>
      </div>
    </div>
  );
}
