import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/logos/logo.png";
import BBB from "../../assets/logos/bbb.png";
import DA from "../../assets/icons/drop.png";

export default function Header() {
  const [show, setShow] = useState(false);
  const [drop, setDrop] = useState(false);

  const toggleDropdown = () => {
    setShow(!show);
  };

  const handleMouseEnter = () => {
    setDrop(true);
  };

  const handleMouseLeave = () => {
    setDrop(false);
  };
  return (
    <div className="headerBase">
      <div className="headerContent">
        <div className="headerFlex">
          <Link
            to="/home"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            style={{ textDecoration: "none", height: "100%" }}
          >
            <img src={Logo} alt="Top Roofing Company in Spring" className="logoImg" />
          </Link>
        </div>
        <div className="headerFlex">
          <div className="navTxtDiv">
            <Link
              to="/home"
              onClick={() => setShow(false)}
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">HOME</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <p className="navTxt">ABOUT</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <Link to="/roofing" style={{ textDecoration: "none" }}>
              <p className="navTxt">ROOFING</p>
            </Link>
          </div>

          <div className="navTxtDivDrop">
            <div
              className="dropDownParentDiv"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {/* <Link to="/service" style={{ textDecoration: "none" }}> */}
              <p className="navTxt">SERVICES</p>
              {/* </Link> */}
              <div className="arrowIMg">
                <img
                  src={DA}
                  alt="arrow"
                  className="arrowImg"
                  style={{
                    transform: drop ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 1.0s",
                  }}
                />
              </div>
            </div>
            {drop && (
              <div
                className="dropDownDiv"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div>
                  <Link
                    to="storm-service"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">STORM SERVICE</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roofing-service"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOFING</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roofing-company"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOFING COMPANY</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-repair"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF REPAIR</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-inspection"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF INSPECTION</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-restoration"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF RESTORATION</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="remodeling-company"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">REMODELING COMPANY</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="kitchen-remodeling"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">KITCHEN REMODELING</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="bedroom-remodeling"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">BATHROOM REMODELING</p>
                  </Link>
                </div>

                <div className="hrDrop"></div>
              </div>
            )}
          </div>
          {/* <div className="navTxtDiv">
            <Link
              onClick={() => setShow(false)}
              to="/blog"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">BLOG</p>
            </Link>
          </div> */}
          <div className="navTxtDiv">
            <Link
              onClick={() => setShow(false)}
              to="/contact-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">CONTACT</p>
            </Link>
          </div>
        </div>
        <div className="headerFlex">
          <div className="rightEndHeader">
            <img src={BBB} alt="logo" className="logoBbb" />
            <a href="tel:832-388-5393">
              <button className="callNowBtn">CALL NOW</button>
            </a>
          </div>
        </div>
      </div>

      <div className="respHeaderContent">
        <Link
          onClick={() => setShow(false)}
          to="/home"
          style={{
            textDecoration: "none",
            height: "100%",
          }}
        >
          <div className="repHeaderFlex">
            <img src={Logo} alt="Top Roofing Company in Spring" className="logoImgResp" />
          </div>
        </Link>
        <div className="repHeaderFlex">
          <button onClick={() => toggleDropdown()} className="btnDiv">
            {show ? (
              <img
                src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/close.png"
                alt="logo"
                className="respIcon"
              />
            ) : (
              <img
                src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/hamburger.png"
                alt="logo"
                className="respIcon"
              />
            )}
          </button>
        </div>
      </div>
      {show && (
        <div className="headerDropdown">
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/home"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">HOME</p>
            </Link>
          </div>
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/about-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginBottom: 0 }}>
                ABOUT
              </p>
            </Link>
          </div>
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/roofing"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginBottom: 0 }}>
                ROOFING
              </p>
            </Link>
          </div>

          <Link
            onClick={() => setDrop(!drop)}
            // to="/service"
            style={{ textDecoration: "none" }}
          >
            <div className="dropDownParentDiv">
              <p className="navTxt">SERVICES</p>
              <div className="arrowIMg">
                <img
                  src={DA}
                  alt="arrow"
                  className="arrowImg"
                  style={{
                    transform: drop ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 1.0s",
                  }}
                />
              </div>
            </div>
          </Link>
          {drop && (
            <div
              className="dropDownDiv"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="storm-service"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">STORM SERVICE</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roofing-service"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOFING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roofing-company"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOFING COMPANY</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-repair"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF REPAIR</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-inspection"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF INSPECTION</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-restoration"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF RESTORATION</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="remodeling-company"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">REMODELING COMPANY</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="kitchen-remodeling"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">KITCHEN REMODELING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="bedroom-remodeling"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">BATHROOM REMODELING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
            </div>
          )}
          {/* <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/blog"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginTop: 0 }}>
                BLOG
              </p>
            </Link>
          </div> */}
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/contact-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">CONTACT</p>
            </Link>
          </div>

          <div className="rightEndHeader">
            <img src={BBB} alt="logo" className="logoBbb" />
          </div>
          <div className="rightEndHeader">
            <a href="tel:832-639-8756">
              <button className="callNowBtn">CALL NOW</button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
