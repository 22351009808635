import React from "react";
import "./Popup.scss";
export default function Popup(props) {
  return (
    <div className="popup">
      <div className="popup_inner1">

        <button className="closeBtn" onClick={props.closePopup}> X </button>
        <img src={props.img} alt="img" className="worksImageCard" />
      </div>
    </div>
  );
}
