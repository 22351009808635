import React, { useState } from "react";
import "./Contact.scss";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import EM from "../../assets/icons/email.png";
import PH from "../../assets/icons/phone.png";
import MP from "../../assets/icons/map.png";
import MAP from "../../assets/map.png";
import { Helmet } from "react-helmet";

export default function Contact() {
  const validationSchema = Yup.object({
    fName: Yup.string().required("* First Name is required"),
    lName: Yup.string().required("* Last Name is required"),
    email: Yup.string().required("* Email Address is required"),
    priPhone: Yup.string().required("* Phone Number is required"),
    message: Yup.string().required("* Message is required"),
  });
  const [contactForm] = useState({
    fName: "",
    lName: "",
    priPhone: "",
    altPhone: "",
    email: "",
    message: "",
  });
  function objectToFormData(obj) {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  }
  const onSubmit = (values, submitProps) => {
    console.log(values);
    const data = {
      FirstName: values.fName,
      LastName: values.lName,
      PrimaryPhoneNumber: values.priPhone,
      AlternatePhoneNumber: values.altPhone,
      Email: values.email,
      Message: values.message,
    };
    const fData = objectToFormData(data);
    fetch(
      "https://script.google.com/macros/s/AKfycbyTbe9wO4comSM7DAix1T9POcLLQVpiEO2Pj02NzMPaV8uu2icoZIONvsdYQ5HZ7kKx/exec",
      {
        method: "POST",
        body: fData,
      }
    )
      .then((res) => {
        if (res.ok) {
          submitProps.resetForm();
          alert("Data send successfully!");
        }
      })
      .catch((err) => console.log("err", err));
  };

  const MyTextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <textarea
          placeholder="Your Comments"
          className="textArea"
          {...field}
          {...props}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <div className="contactUsBase">
        <div className="contactUsContent">
          <div className="contactLeft">
            <div className="leftContactContent">
              <p className="contactTitleTxt">Contact Now</p>
              <div className="contactDetailsDiv">
                <div className="contactIconDiv">
                  <img src={EM} alt="contact" className="contactIcon" />
                </div>
                <p className="contactDetailsTxt">
                  richard.restorationroofing@gmail.com
                </p>
              </div>
              <div className="contactDetailsDiv">
                <div className="contactIconDiv">
                  <img src={MP} alt="contact" className="contactIcon" />
                </div>
                <h2 className="contactDetailsTxt">
                  6902 FM2920, Suite 2, <br />
                  Spring, TX 77379
                </h2>
              </div>
              <div className="contactDetailsDiv">
                <div className="contactIconDiv">
                  <img src={PH} alt="contact" className="contactIcon" />
                </div>
                <p className="contactDetailsTxt">(832) 388-5393</p>
              </div>

              <button className="whiteBtnFF">Working Hours</button>

              <p className="contactDetailsTxtF">
                Monday-Friday -
                <span className="contactDetailsTxtSpan">9:00 AM -5:00 PM</span>
              </p>
              <p className="contactDetailsTxtF">
                Saturday -
                <span className="contactDetailsTxtSpan">
                  By Appointment Only
                </span>
              </p>

              <div className="gMapDiv">
                <a
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/Restoration+Roofing+%26+Remodeling+LLC,+6902+Farm+to+Market+2920+Suite+2,+Spring,+TX+77379,+United+States/@30.0748763,-95.5279994,17z/data=!4m6!3m5!1s0x8647337550c94b33:0x7e7a7b98d49ddbdc!8m2!3d30.0748763!4d-95.5279994!16s%2Fg%2F11ft3fg6cc"
                  target="_blank"
                >
                  <img src={MAP} alt="contact" className="mapImg" />
                  {/* <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                /> */}
                </a>
              </div>
            </div>
          </div>
          <div className="contactRight">
            <div className="rightContactContent">
              <Formik
                initialValues={contactForm}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form className="form">
                      <div className="formTop">
                        <p className="formTitle">We’ve got you covered</p>
                      </div>
                      <div className="formContent">
                        <div className="formContentTop">
                          <div className="field_area">
                            <div>
                              <Field
                                type="text"
                                id="fName"
                                name="fName"
                                placeholder="First Name"
                                className="textField"
                              />
                            </div>
                            <ErrorMessage name="fName">
                              {(error) => <div className="error">{error}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="field_area">
                            <div>
                              <Field
                                type="text"
                                id="lName"
                                placeholder="Last Name"
                                name="lName"
                                className="textField"
                              />
                            </div>
                            <ErrorMessage name="lName">
                              {(error) => <div className="error">{error}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="formContentTop">
                          <div className="field_area">
                            <div>
                              <Field
                                type="text"
                                id="priPhone"
                                name="priPhone"
                                placeholder="Primary Phone"
                                className="textField"
                              />
                            </div>
                            <ErrorMessage name="priPhone">
                              {(error) => <div className="error">{error}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="field_area">
                            <div>
                              <Field
                                type="text"
                                id="altPhone"
                                name="altPhone"
                                placeholder="Alternate Phone"
                                className="textField"
                              />
                            </div>
                            <ErrorMessage name="altPhone">
                              {(error) => <div className="error">{error}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="field_area">
                          <div>
                            <Field
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Email Address"
                              className="textField"
                            />
                          </div>
                          <ErrorMessage name="email">
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="formContentBottom">
                          <div className="field_area">
                            <div>
                              <MyTextArea name="message" rows="5" />
                            </div>
                            <ErrorMessage name="message">
                              {(error) => <div className="error">{error}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="formBtnDiv">
                            <button type="submit" className="submitBtn">
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
