import React from "react";
import "./HelpCard.scss";
import { Link } from "react-router-dom";
export default function HelpCard(props) {
  return (
    <div className="helpListBase">
      <div className="helpListContent">
        <div className="tophelpList">
          <p className="helpListTitle">GAF What to Know</p>
        </div>
      
        <div className="contentDatahelpList">
          {props.array.map((data) => (
            <div className="cardDatahelpLIst" key={data.id}>
              <div className="cardContenthelpList">
                <div className="shadowhelpList"></div>
                <img src={data.image} alt="roof" className="helpListIMages" />
                <div className="dataTxtDiv">
                  <p className="txtInhelpCard">{data.txt}</p>
                  <Link
                    to={data.link}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <button className="blueBtnHelp">{data.btnTxt}</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
