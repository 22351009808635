import React from "react";
import "./CardTop.scss";
import IMG from "../../../assets/home/home1.png";
import { Link } from "react-router-dom";

export default function CardTop() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={IMG} alt="Best restoration company in Texas" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">
              Welcome To Restoration Roofing & Remodeling
            </p>
            <p className="cardSubTxt">
              Restoration, Roofing & Remodeling, LLC is a family owned business
              with the goal of building long-term relationships with all of our
              customers. We do this by providing exceptional service in a timely
              manner using the best installation methods and roofing products in
              the market. Our goal is to make sure that all customers are
              satisfied before leaving the project.
            </p>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/about-us"
            >
              <button className="blueBtn">MORE</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
