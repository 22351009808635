import React from "react";
import "./TopImage.scss";
import IMG from "../../../assets/about/about1.png";

export default function TopImage() {
  return (
    <div className="aboutTopBase">
      <div className="aboutTopContent">
        <div className="absoluteBackShadow"></div>
        <div className="imgTop">
          <img src={IMG} alt="Best roofing and restoration company in USA" className="aboutcardImg" />
        </div>
      </div>
    </div>
  );
}
