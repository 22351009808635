import React from "react";
import "./FinanceTop.scss";
import IMG from "../../../assets/finance/fin.jpg";

export default function FinanceTop() {
  return (
    <div className="finTopBase">
      <div className="shadowFinTop"></div>
      <img className="topFinImage" src={IMG} alt="topFin" />
      <div className="topFinTxtDivision">
        <div className="topFinTxtTitle">
          Restoration Roofing & Remodeling is partnered with Hearth to provide
          our customers with financing options.
        </div>

        <div className="topFinTxtDesp">
          With Hearth, you can see potential financing options for your upcoming
          project. A loan through Hearth helps you pay for your project through
          predictable, monthly payments without tapping into your home equity.
        </div>
        <div className="topFinTxtDesp">
          Find convenient monthly payment options for your project. See your
          personalized payment plans without affecting your credit score now.
        </div>
      </div>
    </div>
  );
}
