import React, { useEffect, useRef, useState } from "react";
import "./Estimate.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import CLOSE from "../../assets/icons/icon.png";
export default function Estimate() {
  const [show, setShow] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("* Your Name is required"),
    email: Yup.string().required("* Email Address is required"),
    phone: Yup.string().required("* Phone Number is required"),
  });
  const [fromData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const toggleVisibility = () => {
    setShow(!show);
  };

  function objectToFormData(obj) {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  }
  const onSubmit = (values, submitProps) => {
    const data = {
      Name: values.name,
      Email: values.email,
      Phone: values.phone,
    };
    const fData = objectToFormData(data);
    fetch(
      "https://script.google.com/macros/s/AKfycbyUUKS-pF227g00mpRLYUMskvuGUPSdtS10lrxCSGdC8sX0YTu-_wUsCqqeZ0Bqbjwi/exec",
      {
        method: "POST",
        body: fData,
      }
    )
      .then((res) => {
        if (res.ok) {
          submitProps.resetForm();
          alert("Data send successfully!");
          setShow(false);
        }
      })
      .catch((err) => console.log("err", err));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log("show", show);
        if (ref.current && !ref.current.contains(event.target)) {
          if (show) {
            console.log("in");
            //   setShow(false);
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  console.log(show);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div className="contactCardBase">
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ x: -1000, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { duration: 0.5, ease: easeInOut },
            }}
            exit={{
              x: -1000,
              opacity: 0,
              transition: { duration: 0.5, ease: easeInOut },
            }}
          >
            <div className="heroSubmitDiv">
              <button className="closeIconDivision" onClick={toggleVisibility}>
                <img src={CLOSE} alt="close me" className="closeBtnPP" />
              </button>
              <Formik
                initialValues={fromData}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form className="form">
                      <div className="textCenterDiv">
                        <p className="formTxtTitle">
                          Get a Free Estimate Today!
                        </p>
                      </div>
                      <div className="textCenterDiv">
                        <p className="formTxtSub">
                          Discover your project's costs with our free estimate
                          form. No commitments, just accurate quotes tailored to
                          your needs
                        </p>
                      </div>
                      <div className="field_area">
                        <div className="txtDiv">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="textField"
                            placeholder="Name"
                          />
                          <ErrorMessage name="name">
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="field_area">
                        <div className="txtDiv">
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className="textField"
                            placeholder="Email Address"
                          />
                          <ErrorMessage name="email">
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="field_area">
                        <div className="txtDiv">
                          <Field
                            type="text"
                            id="phone"
                            name="phone"
                            className="textField"
                            placeholder="Phone Number"
                          />
                          <ErrorMessage name="phone">
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="formBtnDiv">
                        <button type="submit" className="submitBtn">
                          SUBMIT
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="buttonHeroDiv">
        <button className="estimateBtn" onClick={toggleVisibility}>
          <p className="estimateBtnTxt">GET FREE ESTIMATE TODAY</p>
        </button>
      </div>
    </div>
  );
}
