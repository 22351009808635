import React from "react";
import IMG from "../../../src/assets/data/commercial2.jpeg";
export default function CommercialBottom() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid">
          <div className="textBaseMiddle">
            <p className="cardTitleTxtM">
              Trusted Commercial Roofing in Huston
            </p>

            <p className="cardSubTxt">
              With years of experience serving businesses across Huston, roofing
              restoration & remodeling has built a reputation for reliability,
              excellence, and superior customer service. We are committed to
              delivering roofing solutions that provide peace of mind, knowing
              your commercial property is well-protected and built to last.
            </p>
            <p className="cardSubTxt">
              Contact us today to schedule a consultation and discover how our
              commercial roofing solutions can safeguard your business while
              enhancing its value.{" "}
            </p>
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
      </div>
    </div>
  );
}
