import BaseImg from "./base.png";
import GoodImg from "./good.png";
import BestImg from "./best.png";
import BetterImg from "./better.png";
import UltimateImg from "./ultimate.png";
export const timberLine = [
  {
    id: 1,
    img: BaseImg,
    title: "BASE",
    eligibility: "None! Great coverage accompanies purchase of the product.",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed: "130mph with Special Installation",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 2,
    img: GoodImg,
    title: "GOOD",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 3,
    img: BetterImg,
    title: "BETTER",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 4,
    img: BestImg,
    title: "BEST",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 5,
    img: UltimateImg,
    title: "ULTIMATE",
    eligibility:
      "Installation of GAF Shingles + At Least 5 Qualifying Accessory Products & Installed by GAF Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Covered (when necessary)",
    Workmanship: "Not Covered",
  },
];
export const tab_shingles = [
  {
    id: 1,
    img: BaseImg,
    title: "BASE",
    eligibility: "None! Great coverage accompanies purchase of the product.",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed: "130mph with Special Installation",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 2,
    img: GoodImg,
    title: "GOOD",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 3,
    img: BetterImg,
    title: "BETTER",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 4,
    img: BestImg,
    title: "BEST",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
];
export const designer = [
  {
    id: 1,
    img: BaseImg,
    title: "BASE",
    eligibility: "None! Great coverage accompanies purchase of the product.",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed: "130mph with Special Installation",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 2,
    img: GoodImg,
    title: "GOOD",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 3,
    img: BetterImg,
    title: "BETTER",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 4,
    img: BestImg,
    title: "BEST",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 5,
    img: UltimateImg,
    title: "ULTIMATE",
    eligibility:
      "Installation of GAF Shingles + At Least 5 Qualifying Accessory Products & Installed by GAF Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Covered (when necessary)",
    Workmanship: "Not Covered",
  },
];
export const multi_family = [
  {
    id: 1,
    img: BaseImg,
    title: "BASE",
    eligibility: "None! Great coverage accompanies purchase of the product.",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed: "130mph with Special Installation",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 2,
    img: GoodImg,
    title: "GOOD",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products",
    term: "Lifetime",
    protectionPeriod: "10 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Not Covered",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 3,
    img: BetterImg,
    title: "BETTER",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 4,
    img: BestImg,
    title: "BEST",
    eligibility:
      "Installation of GAF Shingles + At Least 3 Qualifying Accessory Products & Installed by GAF Certified or Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Not Covered",
    Workmanship: "Not Covered",
  },
  {
    id: 5,
    img: UltimateImg,
    title: "ULTIMATE",
    eligibility:
      "Installation of GAF Shingles + At Least 5 Qualifying Accessory Products & Installed by GAF Master Elite Roofing Contractor",
    term: "Lifetime",
    protectionPeriod: "50 Years",
    algaeTerm:
      "10 Years for StainGuard-Labeled Shingles & 25 Years for StainGuard Plus-Labeled Shingles and Ridge Cap Shingles",
    windTerm: "15 Years",
    windSpeed:
      "WINDPROVEN Limited Wind Warranty: No maximum wind speed limitation!",
    taerOff: "Covered (when necessary)",
    disposal: "Covered (when necessary)",
    Workmanship: "Not Covered",
  },
];
