import React from "react";
import "./NewRoof.scss";
import TopSession from "../../../components/newRoofSessions/top/TopSession";
import IMG1 from "../../../assets/data/new1.png";
import IMG2 from "../../../assets/data/new2.png";
import IMG3 from "../../../assets/data/new3.png";
import IMG4 from "../../../assets/data/new4.png";
import IMG5 from "../../../assets/data/new5.png";
import IMG6 from "../../../assets/data/new6.png";
import RUF1 from "../../../assets/new-roof/roof1.jpeg";
import RUF2 from "../../../assets/new-roof/roof2.jpeg";
import RUF3 from "../../../assets/new-roof/roof3.jpg";
import RUF4 from "../../../assets/new-roof/roof4.jpg";
import RUF5 from "../../../assets/new-roof/roof5.jpg";
import LeftImgCard from "../../../components/newRoofSessions/leftImgCard/LeftImgCard";
import RightImageCard from "../../../components/newRoofSessions/rightImageCard/RightImageCard";
import TextView from "../../../components/newRoofSessions/textView/TextView";
import Slider from "../../../components/serviceSessions/slider/Slider";
import { Helmet } from "react-helmet";

export default function NewRoof() {
  const sliderArray = [
    {
      id: 1,
      img: RUF1,
    },
    {
      id: 2,
      img: RUF2,
    },
    {
      id: 3,
      img: RUF3,
    },
    {
      id: 4,
      img: RUF4,
    },
    {
      id: 5,
      img: RUF5,
    },
  ];
  const topData = {
    title: "Tips on Keeping Your Roof in Good Shape",
    data: [
      {
        id: 1,
        hard: "Know how old your roof is",
        soft: "If you didn't install it and you're not sure, check the inspection report from when you purchased your home. You can also ask a roofing company or building inspector to look at the roof and estimate its age and condition.",
      },
      {
        id: 2,
        hard: "Spot-check your roof twice a year, once in the spring and in the fall",
        soft: "Use a ladder to access it and look for trouble spots, but also check your attic for damage. Not feeling up to it? Reaching out to Restoration Roofing & Remodeling for help inspecting is always the best option.",
      },
      {
        id: 3,
        hard: "Watch the homes around you",
        soft: "They experience the same weather as yours does and we're likely built around the same time. If your neighbors are getting new roofs, do a roof inspection to see if your home needs one too.",
      },
      {
        id: 4,
        hard: "After a significant weather event",
        soft: "Like heavy winds or a hail storm, check for exterior damage as soon as it's safe.",
      },
    ],
  };

  const Inside = () => {
    return (
      <ul style={{ marginLeft: "20px" }}>
        <li>
          <p className="ans1">
            Look around your attic for signs of leaks, dark spots, holes, or
            sagging sheathing.
          </p>
        </li>
        <li>
          <p className="ans1">
            Check your ceilings for stains, mold, or mildew.
          </p>
        </li>
      </ul>
    );
  };
  const Outside = () => {
    return (
      <div style={{ marginLeft: "20px" }}>
        <p className="ans1">
          If you're comfortable climbing a ladder, you can see your roof up
          close. If not, you can do a relatively thorough inspection from the
          ground using binoculars.
        </p>
        <ul>
          <li>
            <p className="ans1">
              Clean leaves and debris out of gutters and downspouts, check them
              for sagging or leaks.
            </p>
          </li>
          <li>
            <p className="ans1">
              Look in hidden areas, like the underside of the eaves and fascia,
              the board that connects the roof to the outer walls of your house,
              for rotting or signs of animal infestation.
            </p>
          </li>
        </ul>
      </div>
    );
  };
  const Street = () => {
    return (
      <ul style={{ marginLeft: "20px" }}>
        <li>
          <p className="ans1">
            From this distance, look for dark spots, missing shingles, or
            sagging.
          </p>
        </li>
        <li>
          <p className="ans1">
            See if the exterior paint is blistering or peeling.
          </p>
        </li>
        <li>
          <p className="ans1">
            If you live in an area with icy or snowy winters, keep an eye out
            for ice dams ridges of ice that form at the edge of your roof
            because if water can't drain off the roof, it may leak into your
            home.
          </p>
        </li>
      </ul>
    );
  };
  const Examine = () => {
    return (
      <ul style={{ marginLeft: "20px" }}>
        <li>
          <p className="ans1">
            Check for cracked, torn, warped, or missing shingles and popped or
            rusted nails.
          </p>
        </li>
        <li>
          <p className="ans1">
            Look for open seams or joints, and make sure roof vents aren't
            covered or clogged.
          </p>
        </li>
        <li>
          <p className="ans1">
            Make sure pipes and roof penetrations, like your chimney or
            skylights, are sealed and don't have any exposed nails.
          </p>
        </li>
        <li>
          <p className="ans1">
            Make sure flashing, which is used to seal joints and keep water off
            of them, is not damaged or providing inadequate coverage.
          </p>
        </li>
      </ul>
    );
  };
  const bottomData = {
    title: "How to do a roof inspection",
    data: [
      {
        id: 1,
        hard: "Start inside",
        soft: <Inside />,
      },
      {
        id: 2,
        hard: "Step outside",
        soft: <Outside />,
      },
      {
        id: 3,
        hard: "Head to the street",
        soft: <Street />,
      },
      {
        id: 4,
        hard: "Examine the roof",
        soft: <Examine />,
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <TopSession />
      <LeftImgCard
        img={IMG6}
        title="Attic Leaks"
        desp="Check your attic after severe weather. A leak could mean some shingles are damaged, the underlayment, a protective barrier between your shingles and the plywood of your roof has failed, or flashing the material that seals joints on your roof has deteriorated."
      />
      <RightImageCard
        img={IMG5}
        title="Blistering or Peeling Paint"
        desp="Poor ventilation may cause moisture to get trapped in your house, which may cause the exterior paint to peel and blister."
      />
      <LeftImgCard
        img={IMG4}
        title="Ceiling and Wall Stains"
        desp="Leaks from outside the house or moisture trapped inside it can cause stains and possibly mold or mildew."
      />
      <RightImageCard
        img={IMG3}
        title="Sheathing Decay"
        desp="Another issue with poor ventilation and moisture in your layers of roofing materials is the decay of sheathing, also called decking, which is the layer of plywood that forms the foundation of your roof. If you notice a sagging ceiling, broken gutters, leaks, or missing patches of shingles, your sheathing may be damaged."
      />
      <LeftImgCard
        img={IMG2}
        title="Shingle Damage"
        desp="When shingles reach the end of their useful life, you may notice they fall off or become cracked, brittle, or curled which means it may be time for a new roof."
      />
      <RightImageCard
        img={IMG1}
        title="Dark Streaks"
        desp="It may look like dirt, but dark stains on your shingles may be moss, lichen, or blue-green algae. This may not require a new roof, but if and when you do replace your shingles, ask for StainGuard and StainGuard Plus protection, which are available on many GAF shingles."
      />
      <TextView data={topData} />
      <Slider data={sliderArray} />
      <TextView data={bottomData} />
    </div>
  );
}
