import React from "react";
import IMG1 from "../../../assets/svc/complete/1.jpeg";
import IMG2 from "../../../assets/svc/complete/2.jpeg";
import IMG3 from "../../../assets/svc/complete/3.png";
import IMG4 from "../../../assets/svc/complete/4.png";
import IMG5 from "../../../assets/svc/complete/5.png";
import IMG6 from "../../../assets/svc/complete/6.png";
import IMG7 from "../../../assets/svc/complete/7.png";
import IMG8 from "../../../assets/svc/complete/8.png";
import IMG9 from "../../../assets/svc/complete/9.png";
import IMG10 from "../../../assets/svc/complete/10.png";
import IMG11 from "../../../assets/svc/complete/11.png";
import Service from "../Service";
import { Helmet } from "react-helmet";

export default function RoofRestoration() {
  const data = {
    houston: {
      heroImg:
        "https://hrproofing.com.au/shared/content/uploads/roofers-working-on-a-roof-restoration.jpg",
      title: "Roof Restoration in Houston, TX",
      desp: "Make Your Roof Ready for Spring with Our Professional Roof Restoration in Houston, TX",
      cardTxt1:
        "If your roof needs restoration, then you have come to the right place. At Restoration Roofing & Remodeling, we specialize in repairing and restoring roofs to their original condition. We use the latest techniques and equipment to get your roof looking as good as new. We have a wide variety of restoration services available, so please let us know which one will best suit your needs.",
      cardTxt2:
        "If you're looking for professional roof restoration in Houston, TX, contact us today to schedule a consultation so we can show you what we can do for your home!",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
    spring: {
      heroImg:
        "https://hrproofing.com.au/shared/content/uploads/roofers-working-on-a-roof-restoration.jpg",
      title: "Roof Restoration in Spring, TX",
      desp: "Make Your Roof Ready for Spring with Our Professional Roof Restoration in Spring, TX",
      cardTxt1:
        "A roof is one of the most important parts of your home. Not only does it protect you from the rain and snow, but it also provides shelter for your family and property. That?s why it?s important to have a reliable roof restoration in Spring, TX. If you're in need of professional roof restoration services, Restoration Roofing & Remodeling is your ideal choice. When you hire us, you can be sure that we will take care of all the details necessary to restore your roof properly. We have a team of highly skilled professionals who are dedicated to providing quality service, so you can relax and know that your home is in good hands.",
      cardTxt2:
        "Contact us today to schedule a free consultation with our team of experts, and let us help protect your home from potential damage!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
