import React from "react";
import "./RoofingSvc.scss";
import IMG1 from "../../../assets/svc/commercial/1.jpg";
import IMG2 from "../../../assets/svc/commercial/2.jpg";
import IMG3 from "../../../assets/svc/commercial/3.jpg";
import IMG4 from "../../../assets/svc/commercial/4.jpg";
import IMG5 from "../../../assets/svc/commercial/5.jpg";
import IMG6 from "../../../assets/svc/commercial/6.jpg";
import IMG7 from "../../../assets/svc/commercial/7.jpg";
import IMG8 from "../../../assets/svc/commercial/8.jpg";
import IMG9 from "../../../assets/svc/commercial/9.jpeg";
import IMG10 from "../../../assets/svc/commercial/10.jpeg";
import IMG11 from "../../../assets/svc/commercial/11.jpeg";
import IMG12 from "../../../assets/svc/commercial/12.jpeg";

import Service from "../Service";
import { Helmet } from "react-helmet";
export default function RoofingSvc() {
  const data = {
    houston: {
      heroImg:
        "https://cdn.pixabay.com/photo/2015/04/25/13/46/tool-belt-739152_1280.jpg",
      title: "Roofer in Houston, TX",
      desp: "Dedicated to Quality and Professionalism Safe & Reliable Roofing Solutions in Houston, TX",
      cardTxt1:
        "Roofing is one of the most important aspects of a homes exterior. It protects the building from the weather, and it can also add an extra level of curb appeal. However, just like any other home improvement project, roofing needs to be handled by a professional who has the experience and expertise to get the job done right. Restoration Roofing & Remodeling is a professional roofer in Houston, TX that can help you with all of your needs. Here are some of the benefits of choosing Restoration Roofing & Remodeling for your homes roofing needs.",
      cardTxt2:
        "Restoration Roofing & Remodeling offers professional roofing and remodeling services to residents in the Houston area. Our team of experienced professionals is available to provide you with a variety of roofing and remodeling services that will meet your needs and exceed your expectations. Restoration Roofing & Remodeling has been providing quality roofing and remodeling services to the Houston area for over 25 years. We appreciate the importance of customer satisfaction and strive to provide the highest level of service possible. If you're looking for quality roofing in Houston, TX then contact us today!",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
      ],
    },
    spring: {
      heroImg:
        "https://cdn.pixabay.com/photo/2015/04/25/13/46/tool-belt-739152_1280.jpg",
      title: "Roofer in Spring, TX",
      desp: "Restoration Roofing & Remodeling: The Roofer You Can Trust. Experience the Highest Quality Roofing in Spring, TX",
      cardTxt1:
        "When it comes to roofing and remodeling, there's no one better than a professional. At Restoration Roofing & Remodeling, we know what it takes to get your home back in shape from start to finish. From roof repair and replacement to insulation installation and water damage restoration, we have you covered. Our team of experienced professionals is here to help you with any restoration needs you may have. We understand the importance of preserving your property and our team will go the extra mile to ensure that your home is restored to its former glory. If you're looking for a reliable professional roofer in the Spring, TX area, call Restoration Roofing & Remodeling today!",
      cardTxt2:
        "When it comes to quality roofing in Spring, TX, there is no substitute for a professional contractor. Restoration Roofing & Remodeling has been providing the highest quality roofing services in Spring, TX since 1978. We take great pride in our reputation for providing the best possible customer service and ensuring that each and every project is completed to perfection. customers consistently report positive experiences with our work. They appreciate our attention to detail and our commitment to providing top-quality roofing services at a fair price. We understand that not everyone is able to make an appointment, which is why we offer a wide range of same day and next day services. If you're looking for a reputable roofing company who will provide top-quality roofing services at a fair price, look no further than Restoration Roofing & Remodeling. Contact us today to schedule your free consultation!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
