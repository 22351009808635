import React from "react";
import "./Hearth.scss";
import FIN from "../../../assets/finance/fin1.jpg";
import "./Hearth.scss";

export default function Hearth() {
  return (
    <div className="hearthBase">
      <a href="https://app.gethearth.com/financing/18215/25557/prequalify?utm_campaign=18215&utm_content=blue&utm_medium=contractor-website&utm_source=contractor&utm_term=25557">
        <img src={FIN} alt="finanching" className="finImg" />
      </a>
    </div>
  );
}
