import React from "react";
import "./TopButton.scss";

export default function TopButton({ showSpring, setShowSpring }) {
  return (
    <div className="topBtnBase">
      <div className="contentBtnBase">
        <button
          style={{
            backgroundColor: showSpring ? "#312f8d" : "#fff",
            color: showSpring ? "#fff" : "#312f8d",
          }}
          className="contentBase"
          onClick={() => {
            setShowSpring(false);
          }}
        >
          HOUSTON-TX
        </button>
        <button
          style={{
            backgroundColor: !showSpring ? "#312f8d" : "#fff",
            color: !showSpring ? "#fff" : "#312f8d",
          }}
          onClick={() => {
            setShowSpring(true);
          }}
          className="contentBase"
        >
          SPRING-TX
        </button>
      </div>
    </div>
  );
}
