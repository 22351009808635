import VID14 from "../video/youtube/14.mp4";
import VID13 from "../video/youtube/13.mp4";
import VID12 from "../video/youtube/12.mp4";
import VID11 from "../video/youtube/11.mp4";
import VID10 from "../video/youtube/10.mp4";
import VID9 from "../video/youtube/9.mp4";
import VID8 from "../video/youtube/8.mp4";
import VID7 from "../video/youtube/7.mp4";
import VID6 from "../video/youtube/6.mp4";
import VID5 from "../video/youtube/5.mp4";
import VID4 from "../video/youtube/4.mp4";
import VID3 from "../video/youtube/3.mp4";
import VID2 from "../video/youtube/2.mp4";
import VID1 from "../video/youtube/1.mp4";
export const array = [
  {
    id: 1,
    title: "RRR Roof Replacement Drone Footage and commercial 14",
    video: VID14,
  },
  {
    id: 2,
    title: "RRR Roof Replacement Drone Footage and commercial 13",
    video: VID13,
  },
  {
    id: 3,
    title: "RRR Roof Replacement Drone Footage and commercial 12",
    video: VID12,
  },
  {
    id: 4,
    title: "RRR Roof Replacement Drone Footage and commercial 11",
    video: VID11,
  },
  {
    id: 5,
    title: "RRR Roof Replacement Drone Footage and commercial 10",
    video: VID10,
  },
  {
    id: 6,
    title: "RRR Roof Replacement Drone Footage and commercial 9",
    video: VID9,
  },
  {
    id: 7,
    title: "RRR Roof Replacement Drone Footage and commercial 8",
    video: VID8,
  },
  {
    id: 8,
    title: "RRR Roof Replacement Drone Footage and commercial 7",
    video: VID7,
  },
  {
    id: 9,
    title: "RRR Roof Replacement Drone Footage and commercial 6",
    video: VID6,
  },
  {
    id: 10,
    title: "RRR Roof Replacement Drone Footage and commercial 5",
    video: VID5,
  },
  {
    id: 11,
    title: "RRR Roof Replacement Drone Footage and commercial 4",
    video: VID4,
  },
  {
    id: 12,
    title: "RRR Roof Replacement Drone Footage and commercial 3",
    video: VID3,
  },
  {
    id: 13,
    title: "RRR Roof Replacement Drone Footage and commercial 2",
    video: VID2,
  },
  {
    id: 14,
    title: "RRR Roof Replacement Drone Footage and commercial 1",
    video: VID1,
  },
];
