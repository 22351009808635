import React, { useEffect, useRef } from "react";
import Header from "../components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../pages/home/HomeScreen";
import NotFound from "../pages/notFound/NotFound";
import About from "../pages/about/About";
import Footer from "../components/footer/Footer";
import ContactSession from "../components/homeSessions/contact/ContactSession";
// import Blog from "../pages/blog/Blog";
import Contact from "../pages/contact/Contact";
import Service from "../pages/service/Service";
import Video from "../pages/video/Video";
import Finanching from "../pages/financing/Finanching";
import RoofingSvc from "../pages/service/roofing/RoofingSvc";
import RoofingCompany from "../pages/service/roofingCompany/RoofingCompany";
import RoofRepair from "../pages/service/roofRepair/RoofRepair";
import RoofInspection from "../pages/service/roofInspection/RoofInspection";
import RoofRestoration from "../pages/service/roofRestoration/RoofRestoration";
import RemodelingCompany from "../pages/service/remodelingCompany/RemodelingCompany";
import KitchenRemodeling from "../pages/service/kitchenRemodeling/KitchenRemodeling";
import BathRoomRemodeling from "../pages/service/bathRoomRemodeling/BathRoomRemodeling";
import StormService from "../pages/service/storm/StormService";
import Roofing from "../pages/roofing/Roofing";
import VirtualRemodeler from "../pages/roofing/virtualRemodeler/VirtualRemodeler";
import ShingleList from "../pages/roofing/shingleList/ShingleList";
import Warrenty from "../pages/roofing/warrenty/Warrenty";
import NewRoof from "../pages/roofing/newRoof/NewRoof";
import RoofSystem from "../pages/roofing/roofSystem/RoofSystem";
import Attic from "../pages/roofing/attic/Attic";
import './Layout.scss'
import Estimate from "../components/estimate/Estimate";
import Commercial from "../pages/roofing/Commercial";

export default function Layout() {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    console.log("windowSize", windowSize);
  }, [windowSize]);
  return (
    <div className="layoutBase">
      <BrowserRouter>
        <div className="top">
          <Header />
        </div>

        <div className="estimateDiv">
          <Estimate />
        </div>

        <div className="dataLayout">

          <Routes>
            <Route path="/">
              <Route index element={<HomeScreen />} />
              <Route path="home" element={<HomeScreen />} />
              <Route path="about-us" element={<About />} />
              {/* <Route path="service" element={<Service />} /> */}
              <Route path="roofing-service" element={<RoofingSvc />} />
              <Route path="storm-service" element={<StormService />} />
              <Route path="roofing-company" element={<RoofingCompany />} />
              <Route path="roof-repair" element={<RoofRepair />} />
              <Route path="roof-inspection" element={<RoofInspection />} />
              <Route path="roof-restoration" element={<RoofRestoration />} />
              <Route
                path="remodeling-company"
                element={<RemodelingCompany />}
              />
              <Route
                path="kitchen-remodeling"
                element={<KitchenRemodeling />}
              />
              <Route
                path="bedroom-remodeling"
                element={<BathRoomRemodeling />}
              />
              {/* <Route path="blog" element={<Blog />} /> */}
              <Route path="contact-us" element={<Contact />} />
              <Route path="financing" element={<Finanching />} />
              <Route path="videos" element={<Video />} />
              <Route path="roofing" element={<Roofing />} />
              <Route path="commercial" element={<Commercial />} />
              <Route path="lifetime-shingles" element={<ShingleList />} />
              <Route path="virtual-remodeling" element={<VirtualRemodeler />} />
              <Route path="warrenty" element={<Warrenty />} />
              <Route path="new-roof" element={<NewRoof />} />
              <Route path="roof-system" element={<RoofSystem />} />
              <Route path="attic-vent" element={<Attic />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
        <div className="bottom">
          <ContactSession />
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}
