import React from "react";
import "./DataFin.scss";

export default function DataFin() {
  const array = [
    {
      id: 1,
      image: "https://static.thenounproject.com/png/1446406-200.png",
      title: "Why Hearth",
      desp: "Start your project soon. Funding usually within 1-3 business days",
    },
    {
      id: 2,
      image: "https://cdn-icons-png.flaticon.com/512/4341/4341764.png",
      title: "See eligibility without hurting your credit",
      desp: "Pre-qualifying through Hearth doesn't affect your credit score",
    },
    {
      id: 3,
      image: "https://static.thenounproject.com/png/117082-200.png",
      title: "Budget responsibility",
      desp: "Fixed monthly payments and no prepayment penalties",
    },
  ];
  return (
    <div className="finDataBase">
      <div className="finDataContent">
        {array.map((data) => (
          <div className="finData" key={data.id}>
            <div className="finCard">
              <img src={data.image} alt="icon" className="finImgCard" />
              <p className="finTitleCard">{data.title}</p>
              <p className="finDespCard">{data.desp}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
