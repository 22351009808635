import React from "react";
import "./VideoSession.scss";
import IMG from "../../../assets/about/about1.png";
// import BackVideo from "../../../assets/video/vidCrop.mp4";
import { Link } from "react-router-dom";

export default function VideoSession() {
  return (
    <div className="videoHomeBase">
      <div className="videoHomeContent">
        <div className="topVideoDiv">
          <div className="videoFlexT">
            <p className="videoTitleTxt">
              We've got you covered at Restoration, Roofing & Remodeling
            </p>
          </div>
          <div className="videoFlexS">
            <p className="videoSubTxt">
              Customer satisfaction is our top priority. You can trust that your
              next project will be completed correctly and on time, because at
              Restoration, Roofing & Remodeling, we've got you covered.
            </p>
          </div>
          <div className="videoFlexB">
            <Link
              to="/videos"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <button className="blueBtn">VIEW MORE VIDEOS</button>
            </Link>
          </div>
        </div>
        <div className="bottomDiv">
          <img src={IMG} alt="Best roofing and restoration company in USA" className="videoTagBottom" />
          {/* <video className="videoTagBottom" controls preload="metadata">
            <source src={BackVideo} type="video/mp4" />
          </video> */}
        </div>
      </div>
    </div>
  );
}
