import React from "react";
import "./WarrentyPopup.scss";
export default function WarrentyPopup({ closePopup, data }) {
  console.log("data", data);
  return (
    <div className="popup">
      <div
        className="popup_inner"
        // style={{
        //   backgroundImage: `url(${data.img} )`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "contain",
        //   backgroundPosition: "center",
        //   backfaceVisibility: "hidden",
        // }}
      >
        <button className="closeBtn1" onClick={closePopup}>
          X
        </button>
        {/* <img src={data.img} alt="img" className="worksImageCard" /> */}
        <h1 style={{ margin: "0px", marginBottom: "10px" }}>{data.title}</h1>
        <div className="popUpDatDiv">
          <div className="ppDataContent">
            <p className="ppTitle">Eligibility:</p>
            <p className="ppResult">{data.eligibility}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Term:</p>
            <p className="ppResult">{data.term}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Protection Period:</p>
            <p className="ppResult">{data.protectionPeriod}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Algae Term:</p>
            <p className="ppResult">{data.algaeTerm}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Wind Term:</p>
            <p className="ppResult">{data.windTerm}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Max Wind Speed Coverage:</p>
            <p className="ppResult">{data.windSpeed}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Tear-Off:</p>
            <p className="ppResult">{data.taerOff}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Disposal:</p>
            <p className="ppResult">{data.disposal}</p>
          </div>
          <div className="ppDataContent">
            <p className="ppTitle">Workmanship:</p>
            <p className="ppResult">{data.Workmanship}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
