import React from "react";
import "./CardBottom.scss";
import IMG from "../../../assets/home/home3.png";
import IMG1 from "../../../assets/home/img.jpg";
import { Link } from "react-router-dom";

export default function CardBottom() {
  return (
    <>
      <div className="cardBase">
        <div className="cardContent">
          <div className="cardLeftGrid1">
            <div className="imgBase">
              <img src={IMG} alt="cardImg" className="cardImg" />
            </div>
          </div>
          <div className="cardRightGrid">
            <div className="textBase">
              <h2 className="cardTitleTxt1">GAF Premium Roofing Shingles</h2>
              <p className="cardSubTxtL">
                Nothing Protects Quite Like A Roof, And no warranties can cover
                your home quite like GAF's Lifetime Roofing System
              </p>
              <p className="cardSubTxt">
                As certified GAF Installers, we can educate you about the best
                roofing system and warranty for your home. Not all roofs need to
                be replaced and after your FREE roof inspection, we may find you
                only need minor repairs. We will also assess storm damage and
                work with you to file a claim with your insurance company when
                there is hail and wind damage.
              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                to="/roofing"
              >
                <button className="blueBtn">MORE</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="cardBase">
        <div className="cardContent">
          <div className="cardLeftGrid1">
            <div className="imgBase">
              <img src={IMG1} alt="cardImg" className="cardImg" />
            </div>
          </div>
          <div className="cardRightGrid">
            <div className="textBase">
              <h2 className="cardTitleTxt1">Commercial Roofing</h2>
              <p className="cardSubTxtL">
              Engineered for exceptional durability and protection, our commercial roofing solutions are designed to withstand even the harshest conditions, ensuring long-lasting performance for businesses across huston area.
              </p>
              <p className="cardSubTxt">
              We specialize in providing top-tier commercial roofing solutions designed to deliver exceptional durability, protection, and long-lasting performance. Serving businesses across Huston , we understand the unique challenges posed by the region’s harsh weather conditions, from scorching heat to heavy storms. That’s why our roofing systems are engineered with cutting-edge materials and innovative techniques to ensure they not only withstand the elements but exceed industry standards.

              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                to="/commercial"
              >
                <button className="blueBtn">MORE</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
