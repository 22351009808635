import React from "react";
import IMG from "../../../assets/data/algae2.png";
export default function CardMiddle() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid">
          <div className="textBaseMiddle">
            <p className="cardTitleTxtM">
              GAF Time-Release
              <h2 className="cardTitleTxtM">Algae-Fighting Technology</h2>
            </p>
            <p className="cardSubTxt">
              25-year limited warranty against blue-green algae discoloration
            </p>
            <p className="cardSubTxt">
              Time-Release Algae-Fighting Technology is proprietary to GAF.
              Specially engineered capsules are infused throughout with
              thousands of copper microsite's. These breakthrough capsules
              release algae-fighting copper efficiently over time for
              long-lasting algae-fighting power.
            </p>
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
      </div>
    </div>
  );
}
