import React from "react";
import "./InsuraneDetails.scss";
export default function InsuraneDetails(props) {
  const DEPRECIATION = () => {
    return (
      <>
        <p className="ans1">
          Depreciation is the portion of the claim withheld for age, condition
          and wear and tear. Recoverable depreciation is retained by the carrier
          until the loss has been repaired and you have presented the final
          bills to the carrier for reimbursement. Be mindful that with
          recoverable depreciation you either use it or lose it. If the amount
          you spent for repairs is less than the amount of the Actual Cash Value
          (ACV) + deductible + recoverable depreciation then the carrier will
          keep the difference.
        </p>
        <p className="ans1">
          By identifying this up front we can work with you to use any excess
          funds to upgrade your roofing products and accessories to maximize
          what you are getting for your money. We have products available that
          will pay your deductible back in premium savings every year and
          potentially save 100% of a potential loss during the next catastrophic
          weather event. INSURANCE CLAIM PROCESS
        </p>
        <p className="ans1">
          Here are a few tips to help you understand how to work with your
          insurance company through the claims process:
        </p>
        <ol>
          <li>
            <p className="ans1">
              If you suspect damage has occurred to your roof, contact us to
              schedule a free on-site inspection. If damage is visible and it's
              during a wide spread bad weather event, it's important to contact
              us as soon as possible to get on our schedule list so we can get
              to you in a timely manner.
            </p>
          </li>
          <li>
            <p className="ans1">
              If damage has been found, you should contact your agent to request
              an inspection so an adjuster can be assigned and evaluate the
              loss. The claims adjuster should provide you with an itemized
              explanation on your claim settlement including your deductible
              amount and depreciation.
            </p>
          </li>
          <li>
            <p className="ans1">
              Your agent can authorize any temporary repairs necessary to
              prevent further damage to your property.
            </p>
          </li>
          <li>
            <p className="ans1">
              Your insurance company will usually require receipts for any
              emergency repairs you have done in order to reimburse you
              correctly.
            </p>
          </li>
          <li>
            <p className="ans1">
              Take pictures to document the damage prior to any repairs but do
              not make any permanent repairs until the adjuster has had the
              opportunity to inspect the damaged area.
            </p>
          </li>
          <li>
            <p className="ans1">
              Once the inspection is complete, you will receive a claim sheet
              outlining the scope of damage and the funds being made available
              for the repairs, less your deductible. (Most homeowner policies
              grant coverage for full replacement cost provided the repairs are
              actually completed. In these cases, the depreciation is deemed
              recoverable and the final payment is made after your receipts have
              been submitted and processed.)
            </p>
          </li>
          <li>
            <p className="ans1">
              Notify us that your insurance inspection has been completed and we
              can move forward with the buying process of choosing new roofing
              materials and discuss any financing options available should the
              insurance claim not cover the full cost of any upgrades you wish
              to make to your roofing choices.
            </p>
          </li>
        </ol>
      </>
    );
  };
  const DEDUCTIBLES = () => {
    return (
      <>
        <p className="ans1">
          The deductible is the financial portion of the claim that you are
          responsible for, which can range from $250.00 up to 2% of your home
          value. Please beware of any contractor who:
        </p>
        <ul>
          <li>
            <p className="ans1">Claims they can cover your deductible</p>
          </li>
          <li>
            <p className="ans1">
              Provides you with an inaccurate invoice to provide to your
              insurance company.
            </p>
          </li>
          <li>
            <p className="ans1">
              Offers to give you money back after you pay them the entire claim
              amount.
            </p>
          </li>
        </ul>
      </>
    );
  };
  const array = [
    {
      id: 1,
      title:
        "CLAIMS ASSISTANCE AND TIPS FOR WORKING WITH YOUR INSURANCE COMPANY",
      desp1:
        "Experiencing damage to your home can be a very stressful, unsettling and confusing experience. The process and repairs can take time, require a great deal of patience and create a burden of inconvenience especially during times of catastrophic weather events. Here are some things to watch out for and consider to keep yourself from making a decision that will create more stress and expense. These tips will help guide you through the process so you know what to expect while saving yourself time and money.",
      desp2: "",
    },
    {
      id: 2,
      title: "INSURANCE CLAIMS AND COVERAGE",
      desp1:
        "During times of widespread damaging weather, insurance carriers are known to hire inexperienced adjusters to help process the large number of claims they receive. These adjusters are not experienced roofing professionals and often misidentify or leave out vital components that are present on your roof. These inaccuracies will affect the amount of your overall claim and could leave you short of the amount to repair your roof back to it's pre-loss condition.",
      desp2:
        "Our trained and experienced roofing professionals are current on the products used today as well as the installation requirements needed to meet the manufacturer's specifications, current market conditions, and/or building codes, they will work with your adjuster to ensure that all of the necessary variables on your roof that affect the price of replacement are secured on your behalf. This is not something you want to handle on your own.",
    },
    {
      id: 3,
      title: "REPLACEMENT COST VALUE (RCV)",
      desp1:
        "This is what the insurance company has given you to bring your home back to it's pre-loss condition. This is the value of your claim, and we will work closely with your carrier to ensure it is accurate and complete for you to repair your losses.",
      desp2: "",
    },
    {
      id: 4,
      title: "ACTUAL CASE VALUE (ACV)",
      desp1:
        "This is often the first check you will receive from your insurance carrier and is the Replacement Cost Value less your deductible and depreciation. This is the money that your carrier will send you to get the process started. It's important to never give this money to a non-reputable or fly-by night contractor who does not have an established reputation within your community. It could very well be the last time you see them and your money.",
      desp2: "",
    },
    {
      id: 5,
      title: "DEDUCTIBLES",
      desp1: <DEDUCTIBLES />,
      desp2:
        "On a recoverable depreciation policy, you or your contractor could unknowingly commit fraud by representing to your insurance company that the cost of repairs was higher than the final cost. Beware of any company that represents that your roof can be done cheap enough to cover your deductible. In order to do this the contractor will have to severely cut corners to reduce the cost at your expense. This is a common strategy used by fly-by night or Storm Chasers to take advantage of homeowners. We can assure you, it's cheaper to hire a reputable company and have the work done right the first time than it is to go the cheap route resulting in having to hire another contractor, this time 100% out of your pocket to repair the shoddy work. If your deductible is high and you need financing, ask us about our financing options.",
    },
    {
      id: 6,
      title: "DEPRECIATION",
      desp1: <DEPRECIATION />,
      desp2: "",
    },
  ];
  return (
    <div className="roofListBase1">
      <div className="roofListContent1">
        <p className="detailsTitle">INSURANCE CLAIMS ASSISTANCE</p>
        {array.map((data, index) => (
          <>
            <button
              className="Questions "
              onClick={() => {
                props.setId(index + 1);
                if (index + 1 === props.id) {
                  props.setShow(!props.show);
                } else {
                  props.setShow(true);
                }
              }}
            >
              <p className="qtn">{data.title}</p>
              <img
                style={{
                  transform:
                    props.show && props.id === index + 1
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 1.0s",
                }}
                src="https://static.vecteezy.com/system/resources/previews/006/827/566/non_2x/down-arrow-icon-sign-symbol-logo-vector.jpg"
                alt="btn"
                className="arrow"
              />
            </button>

            {props.show && props.id === index + 1 && (
              <div className="insranceDetailsDiv">
                <p className="ans">{data.desp1}</p>
                <p className="ans">{data.desp2}</p>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
