import React from "react";
import "./AboutDetails.scss";
import EXP from "../../../assets/logos/exp.png";
import GAF from "../../../assets/logos/gaf.png";
import RCA from "../../../assets/logos/rca.png";
import HAR from "../../../assets/logos/har.png";
import HOM from "../../../assets/logos/hom.png";
import BBB from "../../../assets/logos/bbb.png";

export default function AboutDetails() {
  return (
    <div className="aboutDetailsBase">
      <div className="aboutDetailsContent">
        <div className="leftTxtAbout">
          <div className="leftTxtAboutContent">
            <h2 className="titleTxtAboutContent">
              Restoration Roofing & Remodeling Master Elite Roofers
            </h2>
            <p className="despTxtAboutContent">
              Restoration, Roofing & Remodeling, LLC is a family owned business
              with the goal of building long-term relationships with all of our
              customers. We do this by providing exceptional service in a timely
              manner using the best installation methods and roofing products in
              the market. Our goal is to make sure that all customers are
              satisfied before leaving the project.
            </p>
            <p className="despTxtAboutContent">
              As certified GAF Installers, we can educate you about the best
              roofing system and warranty for your home. Not all roofs need to
              be replaced and after your FREE roof inspection, we may find you
              only need minor repairs. We will also assess storm damage and work
              with you to file a claim with your insurance company when there is
              hail and wind damage.
            </p>
            <p className="despTxtAboutContent">
              We are a full-service roofing and remodeling company with a wide
              range of expertise. Ready for your dream kitchen? Want to remodel
              that old bathroom? Need to replace damaged carpet with flooring
              that you love? We can complete all of your interior and exterior
              remodeling projects to meet your design preferences. We offer FREE
              estimates and can provide many references from our satisfied
              customers.
            </p>
          </div>
        </div>
        <div className="rightLogoAbout">
          <div className="rightAboutLogoContent">
            <div className="logoBackAbout">
              <img src={GAF} alt="logoImg" className="aboutLogo" />
            </div>
            <div className="logoBackAbout">
              <img src={RCA} alt="logoImg" className="aboutLogo" />
            </div>
            <div className="logoBackAbout">
              <img src={HOM} alt="logoImg" className="aboutLogo" />
            </div>
            <div className="logoBackAbout">
              <img src={HAR} alt="logoImg" className="aboutLogo" />
            </div>
            <div className="logoBackAbout">
              <img src={EXP} alt="logoImg" className="aboutLogo" />
            </div>
            <div className="logoBackAbout">
              <img src={BBB} alt="logoImg" className="aboutLogo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
