import React from "react";
import IMG1 from "../../../assets/svc/kitchen-remodeling/1.jpeg";
import IMG2 from "../../../assets/svc/kitchen-remodeling/2.jpeg";
import IMG3 from "../../../assets/svc/kitchen-remodeling/3.jpeg";
import IMG4 from "../../../assets/svc/kitchen-remodeling/4.jpeg";
import IMG5 from "../../../assets/svc/kitchen-remodeling/5.jpeg";
import IMG6 from "../../../assets/svc/kitchen-remodeling/6.jpeg";
import IMG7 from "../../../assets/svc/kitchen-remodeling/7.jpeg";
import IMG8 from "../../../assets/svc/kitchen-remodeling/8.jpeg";
import Service from "../Service";
import { Helmet } from "react-helmet";

export default function KitchenRemodeling() {
  const Top = () => {
    return (
      <>
        <p>
          Restoration Roofing & Remodeling is a professional kitchen remodeling
          contractor in Spring, TX that provides homeowners and businesses with
          high-quality, affordable kitchen remodeling services. We use the
          latest techniques and technologies to ensure that your kitchen looks
          great, function correctly, and lasts for years to come. When you work
          with Restoration Roofing & Remodeling, you can expect the following
          benefits:
        </p>
        <ul>
          <li style={{ textAlign: "left" }}>
            High-quality services - Our team of experienced professionals are
            dedicated to providing you with top-quality restoration roofing &
            remodeling services. We use the latest techniques and technologies
            to ensure that your kitchen looks great, function correctly, and
            lasts for years to come.
          </li>
          <li style={{ textAlign: "left" }}>
            Affordable prices - Our prices are affordable compared to other
            professional kitchen remodeling companies in Spring, TX. You won't
            have to break the bank to get highquality restoration roofing &
            remodeling services from us.
          </li>
        </ul>
      </>
    );
  };
  const data = {
    houston: {
      heroImg:
        "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
      title: "Kitchen Remodeling in Houston, TX",
      desp: "Upgrade Your Cooking and Dining Experience with Kitchen Remodeling in Houston, TX",
      cardTxt1:
        "Restoration Roofing & Remodeling is a professional kitchen remodeling services provider in Houston, TX. We are experienced in restoring roofs and remodeling kitchens. We have the knowledge, skills, and tools necessary to complete your project successfully. We offer a variety of services, including: kitchen remodeling, new kitchen installation, renovation, redesign, refinishing and many more. If you're looking for a company that can provide professional kitchen remodeling in Houston, TX that will exceed your expectations, then look no further than Restoration Roofing & Remodeling. We are dedicated to providing the best possible service at a fair price, and we will go above and beyond to ensure that you're happy with the results!",
      cardTxt2: "",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
      ],
    },
    spring: {
      heroImg:
        "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
      title: "Kitchen Remodeling in Spring, TX",
      desp: "Upgrade Your Cooking and Dining Experience with Kitchen Remodeling in Spring, TX",
      cardTxt1: <Top />,
      cardTxt2:
        "If you?re thinking of doing some kitchen remodeling in Spring, TX but don?t know where to start or who to call, reach out to Restoration Roofing & Remodeling today. We?ll be happy to help.",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
