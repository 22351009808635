import React from "react";
import "./RoofingCompany.scss";
import IMG1 from "../../../assets/svc/commercial/1.jpg";
import IMG2 from "../../../assets/svc/commercial/2.jpg";
import IMG3 from "../../../assets/svc/commercial/3.jpg";
import IMG4 from "../../../assets/svc/commercial/4.jpg";
import IMG5 from "../../../assets/svc/commercial/5.jpg";
import IMG6 from "../../../assets/svc/commercial/6.jpg";
import IMG7 from "../../../assets/svc/commercial/7.jpg";
import IMG8 from "../../../assets/svc/commercial/8.jpg";
import IMG9 from "../../../assets/svc/commercial/9.jpeg";
import IMG10 from "../../../assets/svc/commercial/10.jpeg";
import IMG11 from "../../../assets/svc/commercial/11.jpeg";
import IMG12 from "../../../assets/svc/commercial/12.jpeg";
import Service from "../Service";
import { Helmet } from "react-helmet";
export default function RoofingCompany() {
  const data = {
    houston: {
      heroImg:
        "https://media.istockphoto.com/id/626486888/photo/roofer-builder-worker.jpg?s=1024x1024&w=is&k=20&c=v9esrPgSeEtwdxFjhkYBPq1BFfRzVQmFxMdZiSOwZRg=",
      title: "Roofing Contractor in Houston, TX",
      desp: "Get the High-Quality Roofing Solutions You Deserve with the best Roofing Company in Houston, TX. Restore your home with Professional Roofing contractor in Houston, TX",
      cardTxt1:
        "A roof is one of the most important parts of your home. Not only does it protect you from the elements, but it's also a source of comfort and style. A properly maintained roof can also help you save money on energy bills. Restoration Roofing & Remodeling is a local roofing company in Houston, TX that specializes in restoring homes with professional roofing services. We offer a wide range of services that can restore your home to its former glory. From repairing and replacing roofs to installing new roofs, we have the experience and knowledge necessary to get the job done right.",
      cardTxt2:
        "If you're looking for a reliable and professional roofing contractor in Houston, TX, look no further than Restoration Roofing & Remodeling. At Restoration Roofing & Remodeling, we specialize in all types of roofing and remodeling projects. From simple repairs to full roof replacements, we have the experience and skills necessary to get the job done right. Our team of experts is dedicated to providing quality service at fair prices. We understand that not every project is the same, which is why we offer a variety of services tailored to each customer's needs. Contact us today to learn more about our restoration roofing & remodeling services in Houston, TX.",
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
      ],
    },
    spring: {
      heroImg:
        "https://media.istockphoto.com/id/626486888/photo/roofer-builder-worker.jpg?s=1024x1024&w=is&k=20&c=v9esrPgSeEtwdxFjhkYBPq1BFfRzVQmFxMdZiSOwZRg=",
      title: "Roofing Contractor in Spring, TX",
      desp: "Get the High-Quality Roofing Solutions You Deserve with the best Roofing Company in Spring, TX. Bring Your Home to Life with the best roofing contractor in Spring, TX",
      cardTxt1:
        "Restoration Roofing & Remodeling is a professional roofing contractor in Spring, TX that specializes in providing high-quality roofing and remodeling services to businesses and homeowners. Our team of experienced professionals has the expertise needed to complete any type of roofing project, from a simple repair or replacement to a full renovation. Our commitment to quality workmanship means that we always adhere to industry standards and meet or exceed customer expectations.",
      cardTxt2:
        "If you're looking for a roofing company in Spring, TX who can provide high-quality services at a fair price, look no further than Restoration Roofing & Remodeling. With experienced professionals at your disposal, you can rest assured that your home's roof will be in good hands. There are a number of reasons why Spring is such a great place to Restore Your Home's Roof. First and foremost, the area is well-known for its exceptional weather conditions. This means that roofs can be repaired or replaced without worrying about damaging the underlying structures or causing further damage due to extreme weather conditions. Let us help you improve your business's weather protection with our expertly crafted roofs and remodulation.",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
        {
          id: 9,
          img: IMG9,
        },
        {
          id: 10,
          img: IMG10,
        },
        {
          id: 11,
          img: IMG11,
        },
        {
          id: 12,
          img: IMG12,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
