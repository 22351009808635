import React from "react";
import "./ServiceList.scss";

export default function ServiceList(props) {
  return (
    <div className="svcListBase">
      <div className="svcListContent">
        <div className="topSvcList">
          <p className="svcListTitle">Services We Offer Include</p>
        </div>
        <div className="contentDataSvcList">
          {props.data.map((data) => (
            <div className="cardDataSvcLIst" key={data.id}>
              <div className="cardContentSvcList">
                <div className="shadowSvcList"></div>
                <img src={data.image} alt="roof" className="svcListIMages" />
                <p className="txtInSvcCard">{data.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
