import React from "react";
import "./RightImageCard.scss";
export default function RightImageCard(props) {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid">
          <div className="textBaseMiddle">
            <p className="cardTitleTxtM">{props.title}</p>

            <p className="cardSubTxt">{props.desp}</p>
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="imgBase">
            <img src={props.img} alt="cardImg" className="cardImg" />
          </div>
        </div>
      </div>
    </div>
  );
}
