import React from "react";
import "./AboutNumbers.scss";
export default function AboutNumbers() {
  return (
    <div className="aboutNumbersBase">
      <div className="aboutNumbersContent">
        <div className="aboutNumbersGridBaseC">
          <p className="aboutNumberTitleTxt">1000+</p>
          <p className="aboutNumberDespTxt">Roofs</p>
        </div>
        {/* <div className="aboutNumbersGridBaseC">
          <p className="aboutNumberTitleTxt">1 Million</p>
          <p className="aboutNumberDespTxt">Trusted customers</p>
        </div> */}
        <div className="aboutNumbersGridBaseC">
          <p className="aboutNumberTitleTxt">100+</p>
          <p className="aboutNumberDespTxt">Employees</p>
        </div>
        <div className="aboutNumbersGridBaseC">
          <p className="aboutNumberTitleTxt">Location</p>
          <p className="aboutNumberDespTxt">
            6902 fm 2920 Suite 2 Spring, TX 77379
          </p>
        </div>
      </div>
    </div>
  );
}
