import React from "react";
import { Helmet } from "react-helmet";

export default function Attic() {
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <iframe
        height="1000px"
        name=" attic-vent"
        title="Attic"
        src="https://www.gaf.com/en-us/roofing-products/residential-roofing-products/ventilation-and-attic-vents?Action=GetGrid&amp;cbviewer=none"
        style={{ border: "none" }}
        width="100%"
      ></iframe>
    </div>
  );
}
