import React from "react";
import "./NotFound.scss";
import { Helmet } from "react-helmet";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <div className="centerNotFOund">
        <img
          src="https://static.vecteezy.com/system/resources/previews/005/883/254/original/page-not-found-404-error-concept-illustration-free-vector.jpg"
          alt="not found"
          className="notFoundImg"
        />
      </div>
    </>
  );
}
