import React from "react";
import "./BlockSession.scss";
import HAN from "../../../assets/icons/hand.png";
import PPL from "../../../assets/icons/ppl.png";
import RUF from "../../../assets/icons/roof.png";
import STR from "../../../assets/icons/star.png";
import C1 from "../../../assets/home/homeCard1.png";
import C2 from "../../../assets/home/homeCard2.png";
import C3 from "../../../assets/home/homeCard3.png";
import C4 from "../../../assets/home/homeCard4.png";

export default function BlockSession() {
  return (
    <div className="blockBase">
      <div className="blockGrid">
        <div className="blockDatDiv">
          <div className="dataCOntentBlock">
            <div className="blockImgDiv">
              <img src={STR} alt="icon" className="iconImg" />
            </div>
            <div className="blockTitleDiv">
              <p className="blockTitleTxt">Our Unique Approach</p>
            </div>
            <div className="blockDataDiv">
              <p className="blockDataTxt">
                Discover how we differentiate ourselves with our innovative and
                customer-centric approach. We prioritize understanding your
                specific needs and tailor our solutions accordingly.
              </p>
            </div>
          </div>
        </div>

        <div className="blockShadow"></div>

        <img src={C1} alt="roof" className="blockImg" />
      </div>
      <div className="blockGrid">
        <div className="blockDatDiv">
          <div className="dataCOntentBlock">
            <div className="blockImgDiv">
              <img src={RUF} alt="icon" className="iconImg" />
            </div>
            <div className="blockTitleDiv">
              <p className="blockTitleTxt">Unparalleled Expertise</p>
            </div>
            <div className="blockDataDiv">
              <p className="blockDataTxt">
                Our commitment to excellence begins with our team of
                professionals, each a master in their respective field. From
                roofing specialists who understand the intricacies of
                weather-resistant designs to remodeling experts who breathe life
                into spaces, our collective knowledge forms the backbone of our
                unparalleled expertise.
              </p>
            </div>
          </div>
        </div>
        <div className="blockShadow"></div>
        <img src={C2} alt="roof" className="blockImg" />
      </div>
      <div className="blockGrid">
        <div className="blockDatDiv">
          <div className="dataCOntentBlock">
            <div className="blockImgDiv">
              <img src={PPL} alt="icon" className="iconImg" />
            </div>
            <div className="blockTitleDiv">
              <p className="blockTitleTxt">Customer Centric Philosophy</p>
            </div>
            <div className="blockDataDiv">
              <p className="blockDataTxt">
                We believe that every project is a partnership, and that starts
                with active listening. Our attentive team takes the time to
                understand your vision, preferences, and requirements. Your
                input shapes the foundation of our designs, ensuring that your
                unique style is woven into every aspect of the project.
              </p>
            </div>
          </div>
        </div>
        <div className="blockShadow"></div>
        <img src={C3} alt="roof" className="blockImg" />
      </div>
      <div className="blockGrid">
        <div className="blockDatDiv">
          <div className="dataCOntentBlock">
            <div className="blockImgDiv">
              <img src={HAN} alt="icon" className="iconImg" />
            </div>
            <div className="blockTitleDiv">
              <p className="blockTitleTxt">Exclusive Benifits and Guarantees</p>
            </div>
            <div className="blockDataDiv">
              <p className="blockDataTxt">
                Elevate your roofing and remodeling experience with us. Our
                exclusive benefits and guarantees redefine what it means to
                partner with a roofing and remodeling company. Discover the
                difference – your dream space is just a step away.
              </p>
            </div>
          </div>
        </div>
        <div className="blockShadow"></div>
        <img src={C4} alt="roof" className="blockImg" />
      </div>
    </div>
  );
}
