import React from "react";
import "./Preventive.scss";
export default function Preventive() {
  return (
    <div className="roofListBase1">
      <div className="roofListContent1">
        <p className="detailsTitle">PREVENTATIVE MAINTENANCE</p>

        <p className="ans">
          Preventative care and maintenance protect and extend the life of
          your roof while saving you money. These inspections can detect repairs
          and leaks early on preventing further roof damage that, when neglected
          cannot be repaired resulting in a full roof replacement job and
          expense.
        </p>
        <p className="ans">
          Some roofs require more maintenance while others require less. Our
          roof specialists take the time to understand what your specific roof
          needs are so that it will work efficiently and be safe for years to
          come. Biannual roof inspections are recommended, but you should speak
          with one of our roofing professionals before determining the right
          plan for you and your home.
        </p>
        <p className="ans">
          We start by gathering the known history of your roof and then
          performing a physical inspection by a qualified roofing professional
          that includes:
        </p>
        <ul>
          <li>
            <p className="ans">
              Clearing debris from your roof (up to 100 square feet)
            </p>
          </li>
          <li>
            <p className="ans">
              Clearing debris from HVAC units, pipes, and around shingles
            </p>
          </li>
          <li>
            <p className="ans">
              Checking for moss and algae growth (if growth has occurred, a
              specialist will discuss a low-cost treatment option)
            </p>
          </li>
          <li>
            <p className="ans">
              Checking for deterioration and holes on shingles, flashing, hips,
              ridges, and nails/staples
            </p>
          </li>
          <li>
            <p className="ans">
              Re-securing loose shingles and replacing damaged ones
            </p>
          </li>
          <li>
            <p className="ans">Clearing gutters and testing downspouts</p>
          </li>
          <li>
            <p className="ans">Evaluating tree overhangs</p>
          </li>
          <li>
            <p className="ans">
              Evaluating weather damage, making minor fixes and repairs,
              diagnosing larger problems and providing repair options
            </p>
          </li>
          <li>
            <p className="ans">Checking chimneys, mortar and parapet walls</p>
          </li>
          <li>
            <p className="ans">Checking edge metal</p>
          </li>
          <li>
            <p className="ans">Checking for roof sagging due to water damage</p>
          </li>
          <li>
            <p className="ans">Cleaning pitch pockets</p>
          </li>
          <li>
            <p className="ans">Checking seams</p>
          </li>
        </ul>
        <p className="ans">
          Our experts are highly trained in preventative care and roof
          maintenance and choosing to have your roof maintained is one of the
          best decisions and investments you can make as a home owner.
        </p>
      </div>
    </div>
  );
}
