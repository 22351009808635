import React from "react";
import IMG1 from "../../../assets/svc/siding/1.jpeg";
import IMG2 from "../../../assets/svc/siding/2.jpeg";
import IMG3 from "../../../assets/svc/siding/3.jpeg";
import IMG4 from "../../../assets/svc/siding/4.jpeg";
import IMG5 from "../../../assets/svc/siding/5.png";
import IMG6 from "../../../assets/svc/siding/6.png";
import IMG7 from "../../../assets/svc/siding/7.png";
import IMG8 from "../../../assets/svc/siding/8.jpeg";
import Service from "../Service";
import { Helmet } from "react-helmet";

export default function BathRoomRemodeling() {
  const Bottom = () => {
    return (
      <>
        <p>Some of the things we can do for you include:</p>
        <ul>
          <li style={{ textAlign: "left" }}>
            Repairing or replacing damaged or obsolete materials and fixtures
          </li>
          <li style={{ textAlign: "left" }}>
            Adding new tile, wood floors, tiled walls or cabinets
          </li>
          <li style={{ textAlign: "left" }}>
            Reinstalling windows and door frames
          </li>
          <li style={{ textAlign: "left" }}>
            Installing new ventilation and heating systems
          </li>
        </ul>
      </>
    );
  };
  const data = {
    houston: {
      heroImg:
        "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
      title: "Bathroom Remodeling in Houston, TX",
      desp: "Get the Bathroom of Your Dreams with Professional Bathroom Remodeling in Houston, TX",
      cardTxt1:
        "Bathroom remodeling is something that everyone wants to do at some point in their lives. Whether it's a simple update like new tiles or a more extensive project like a full bathroom renovation, there's a lot of excitement around it. One big reason for this is that bathrooms are one of the most frequently used spaces in our homes. Restoration Roofing & Remodeling is a company that specializes in bathroom remodeling. We understand how important it is to have a beautiful, functional bathroom that meets your needs and preferences. We have years of experience providing high-quality bathroom remodeling in Houston, TX. Our team of experienced professionals can help you transform your bathroom into something you will be proud to call home.",
      cardTxt2: <Bottom />,
      cardImg:
        "https://strongholdroofing.com/wp-content/uploads/2022/03/Benefits-of-Using-Prefabricated-Roofing.jpeg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
      ],
    },
    spring: {
      heroImg:
        "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
      title: "Bathroom Remodeling in Spring, TX",
      desp: "Get the Bathroom of Your Dreams with Professional Bathroom Remodeling in Spring, TX",
      cardTxt1:
        "Looking for a bathroom remodeling in Spring, TX that can help you restore your home's roof and walls? Look no further than Restoration Roofing & Remodeling. Our team of experts is dedicated to providing the highest quality restoration services available, and we know how to take care of your needs. Our team is experienced in many different types of restoration work, including roofing and wall reconstruction. We also have the knowledge and resources necessary to complete any type of bathroom remodeling project, from simple repairs to completely new construction.",
      cardTxt2:
        "If you're looking for a company that can provide high-quality restoration services for your home's roof and walls, look no further than Restoration Roofing & Remodeling. Contact us today to schedule a free consultation!",
      cardImg:
        "https://www.sempersolaris.com/wp-content/uploads/2021/01/semper-roofing-contractor.jpg",
      services: [
        {
          id: 1,
          title: "Roof Inspection",
          image:
            "https://bigleagueroofers.com/wp-content/uploads/2022/07/Real-Estate-Roof-Inspection.jpg",
        },
        {
          id: 2,
          title: "Roof Repair",
          image:
            "https://abi-construction-inc.com/images/roofing/roof-repair_w350@2x.jpg",
        },
        {
          id: 3,
          title: "Roof Restoration",
          image:
            "https://elpasoroofingco.com/wp-content/uploads/2018/03/what-is-roof-replacement.jpg",
        },
        {
          id: 4,
          title: "Remodeling",
          image:
            "https://media.angi.com/s3fs-public/Man-assembling-wardrobe.jpg?impolicy=leadImage",
        },
        {
          id: 5,
          title: "Kitchen Remodeling",
          image:
            "https://sweeten.com/wp-content/uploads/2022/03/Los-Angeles-kitchen-remodel-cost-guide.webp",
        },
        {
          id: 6,
          title: "Bathroom Remodeling",
          image:
            "https://www.thespruce.com/thmb/G24HhUEFDK1K-yxfyE-xE8gQ3iM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-surface-bathroom-remodeling-1821364-hero-e41375e549304244b89b351b0396791b.jpg",
        },
      ],
      portfolio: [
        {
          id: 1,
          img: IMG1,
        },
        {
          id: 2,
          img: IMG2,
        },
        {
          id: 3,
          img: IMG3,
        },
        {
          id: 4,
          img: IMG4,
        },
        {
          id: 5,
          img: IMG5,
        },
        {
          id: 6,
          img: IMG6,
        },
        {
          id: 7,
          img: IMG7,
        },
        {
          id: 8,
          img: IMG8,
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <Service data={data} />
    </div>
  );
}
