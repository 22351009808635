import React from "react";
import "./About.scss";
import TopImage from "../../components/aboutSessions/topImg/TopImage";
import AboutDetails from "../../components/aboutSessions/aboutDetails/AboutDetails";
import AboutNumbers from "../../components/aboutSessions/aboutNumbers/AboutNumbers";
import AboutVideo from "../../components/aboutSessions/aboutVideo/AboutVideo";
import AboutCard from "../../components/aboutSessions/aboutCard/AboutCard";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div>
      <Helmet>
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <meta
          name="description"
          content="We are the top Roofing and Restoration company in Texas, USA. Elevate your home's beauty and value with our trusted roofing and remodeling services in Spring."
        />
      </Helmet>
      <TopImage />
      <AboutDetails />
      <AboutNumbers />
      <AboutVideo />
      <div id="reviews">
        <AboutCard />
      </div>
    </div>
  );
}
