import React from "react";
import IMG from "../../../src/assets/data/commercial3.jpg";
import { Link } from "react-router-dom";

export default function CommercialMiddle() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">
              Why Choose Our Commercial Roofing Solutions?
            </p>
            <p className="cardSubTxt">
              <strong>Unmatched Durability:</strong> Our roofs are built to handle extreme Houston weather, including heavy rains, high winds, and intense UV exposure.
            </p>
            <p className="cardSubTxt">
              <strong>Enhanced Protection:</strong> Protect your business assets and operations with roofing systems designed to resist leaks, corrosion, and other common issues.
            </p>
            <p className="cardSubTxt">
              <strong>Energy Efficiency:</strong> We offer energy-efficient roofing options that help reduce utility costs while promoting a sustainable approach.
            </p>
            <p className="cardSubTxt">
              <strong>Custom Solutions:</strong> Each roofing project is tailored to your building's design and operational needs, ensuring a perfect fit every time.
            </p>
            <p className="cardSubTxt">
              <strong>Certified Expertise:</strong> Our team of highly trained professionals uses the latest technology and techniques to ensure precise installations and repairs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
